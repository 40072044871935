import { ActionDefinition, ActionState, EvaluatedAsset } from '../types';
import { set } from '../cas/module';

const setProperty: ActionDefinition = {
  supports: ['composite', 'material', 'model', 'scene', 'texture', 'vector'],
  label: 'Set Property',
  schema: {
    path: { type: 'Path', label: 'Property' },
    metadataQuery: { type: 'Object', label: 'Metadata Query' },
    value: { type: 'PathProperty', label: 'Value', from: 'path' },
  },
  update: (
    input: ActionState,
    evalAsset: EvaluatedAsset,
    { path, value }: { path: any; value: any }
  ) => {
    if (!path || value === undefined) {
      return input;
    }
    return { ...input, assetState: set(input.assetState, path, value) };
    // const resolvedPath = propertyPath.resolvePathToProperty(store)(path);
    // if (!resolvedPath) return;
    // const { scenePath, childProp, operator, op, propertyName } = resolvedPath;

    // if (childProp) {
    //   const currentValue = operator.get(op, propertyName);
    //   value = { ...currentValue, [childProp]: value };
    // }
    // store.dispatch(set(scenePath, value, { ignoreMissing: true }));
  },
};

export default setProperty;
