import type { SceneGraphItem } from '../types/public';

const graphObjectFactory = (
  type: SceneGraphItem['type'],
  name: SceneGraphItem['name'],
  children: SceneGraphItem['children']
): SceneGraphItem => ({
  type,
  name,
  children,
});

export const toGraph = (object: any): SceneGraphItem[] =>
  object.children.map((child: any) =>
    graphObjectFactory(child.type, child.name || '', toGraph(child))
  );
