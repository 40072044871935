import { ActionDefinition, ActionState, EvaluatedAsset } from '../types';

const action: ActionDefinition = {
  supports: ['item', 'material', 'model', 'scene', 'texture'],
  label: 'Set Attribute Visible',
  schema: {
    attributeId: { type: 'Attribute', label: 'Attribute' },
    visible: { type: 'Boolean', label: 'Visible' },
  },
  update: (
    input: ActionState,
    evalAsset: EvaluatedAsset,
    { attributeId, visible }: any
  ) => {
    // console.log('set attr visible?', attributeId, visible);
    // const inputState = input.attributeStates[attributeId];

    if (!attributeId) return input;

    const inputState = input.attributeStateMap[attributeId];
    if (!inputState) throw new Error('Invalid Attribute State');

    return {
      ...input,
      attributeStateMap: {
        ...input.attributeStateMap,
        [attributeId]: { ...inputState, visible },
      },
    };

    // return configurator.setAttributeState(assetState, attributeId, {
    //   visible,
    // });
  },
};
export default action;
