import { ActionDefinition, ActionState, EvaluatedAsset } from '../types';
import { set } from '../cas/module';

const setVisibility: ActionDefinition = {
  supports: ['material', 'model', 'scene', 'texture'],
  label: 'Set Visibility',
  schema: {
    nodes: { type: 'Array', of: { type: 'Node' }, label: 'Nodes' },
    value: { type: 'Boolean', label: 'Visible' },
  },
  update: (
    input: ActionState,
    evalAsset: EvaluatedAsset,
    args: { nodes: string[]; value: boolean }
  ) => {
    const { nodes, value } = args;
    return {
      ...input,
      assetState: nodes.reduce((s, id) => {
        // console.log('vis?', state.nodes[id]);
        return set(s, [id, 'Properties', 'visible'], value);
      }, input.assetState),
    };
  },
};
export default setVisibility;
