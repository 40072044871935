import React from 'react';
import { ThreekitApiOptions } from '../types';

const defaultContext: ThreekitApiOptions = {
  skipFiles: false,
  addAsset: (id: string, api: Function, parentId?: string) => null,
};
const ApiContext = React.createContext(defaultContext);

export default ApiContext;
