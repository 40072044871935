import { CasState, Path } from '../types';
import { initObject } from './initObject';
import { lookups } from './lookups';

function getParentObj(state: CasState, path: Path) {
  if (!path.length) return [null, null];
  // if (path[0] === 'attributes') {
  //   return [state.attributes, null];
  // }
  return getIn(state.nodes, path.slice(0, path.length - 1));
}

const getIn = (obj: any, path: any[]) => {
  // let result = obj;
  let parentId;

  // for (const key of path) {
  //   if (!result[key]) throw new Error(`Unknown path: ${JSON.stringify(path)}`);
  //   result = result[key];
  //   if (result.id) parentId = result.id;
  // }
  const result = path.reduce((inter, key) => {
    if (!inter[key]) {
      console.log('no key?', key, 'in', inter);
      throw new Error(`Unknown path: ${JSON.stringify(path)}`);
    }
    if (inter[key].id) parentId = inter[key].id;
    // console.log('getIN', obj, inter, key, path);
    return inter[key];
    // inter = result[key];
    // if (result.id) parentId = result.id;
    // return inter;
  }, obj);
  return [result, parentId];
};

function refById(type: string) {
  return type === 'Node';
}

export default function addDiff(
  state: CasState,
  payload: { path: Path; value: any; type: string }
) {
  const { value, path, type } = payload;
  const obj = initObject(type, value, path[path.length - 2], lookups);
  // console.log('addDiff', type, obj);

  // console.log('addNode to state?', payload, obj);
  const [parentObj, parentId] = getParentObj(state, path);
  const pathIdx = path[path.length - 1];

  const saveRef = refById(type) ? obj.id : obj;
  if (parentObj) {
    // console.log('parentObj?', parentObj, pathIdx);
    if (pathIdx === -1) parentObj.push(saveRef);
    else {
      if (Array.isArray(parentObj)) parentObj.splice(pathIdx, 0, saveRef);
      else parentObj[pathIdx] = saveRef;
    }
  }
  if (type === 'Node') {
    state.nodes[obj.id] = obj;
  } else if (type === 'Attribute') {
    state.attributes[obj.id] = obj;
  } else {
    throw new Error(`Unknown CAS Object type ${type}`);
  }
}
