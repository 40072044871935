import { ActionDefinition, ActionState, EvaluatedAsset } from '../types';
import resolveNodeTags from './resolveNodeTags';
import { set } from '../cas/module';

const setMaterial: ActionDefinition = {
  supports: ['material', 'model', 'scene', 'texture'],
  label: 'Set Material',
  schema: {
    nodes: { type: 'Array', of: { type: 'Node' }, label: 'Nodes' },
    metadataQuery: { type: 'Object', label: 'Metadata Query' },
    value: { type: 'Asset', assetType: 'Material', label: 'Asset' },
    instance: { type: 'Boolean', label: 'Instance' },
  },
  update: (
    input: ActionState,
    evalAsset: EvaluatedAsset,
    args: { nodes: string[]; value: any; instance: boolean }
  ) => {
    // console.log('set Material action', args, state);
    const { nodes = [], value, instance } = args;
    if (!value) return input;

    const sceneNodes = resolveNodeTags(input.assetState, nodes);

    return {
      ...input,
      assetState: sceneNodes.reduce((currState, node) => {
        return set(currState, [node.id, 'Material', 'reference'], value);
      }, input.assetState),
    };

    //   if (!nodes.length) {
    //     return;
    //   }
    //   const ids = resolveNodeTags(store, configurator.assetId, nodes);
    //   await Promise.all(
    //     Object.keys(ids).map(async (id) => {
    //       let matId = value;
    //       if (typeof value === 'string' && instance) {
    //         const k = `${id}-${value}`;
    //         matId = setMatInstances[k];
    //         if (!matId) {
    //           matId = await store.dispatch(
    //             duplicateNode(value, { parentId: null })
    //           );
    //           setMatInstances[k] = matId;
    //           const dupnode = store.get('assetState').nodes[matId];
    //           dupnode.parent = id;
    //         }
    //       }
    //       const q = { id, plug: 'Material', property: 'reference' };
    //       return store.dispatch(set(q, matId));
    //     })
    //   );
  },
};

export default setMaterial;
