// @ts-ignore
import computeTangents from './computeTangents';
import doubleSide from './doubleSide';
import { flatNormalMap } from './normals';
import flipMesh from './flipMesh';
// @ts-ignore
import removeDups from './removeDuplicateValues';
import setNormalsFromSmoothingGroups from './setNormalsFromSmoothingGroups';
import transform from './transform';
// @ts-ignore
import triangulateMesh from './triangulateMesh';
import uvWarp from './uvWarp';

export default {
  computeTangents,
  doubleSide,
  flatNormalMap,
  flipMesh,
  removeDups,
  setNormalsFromSmoothingGroups,
  transform,
  triangulateMesh,
  uvWarp,
};
