import React from 'react';
import axios from 'axios';
import { ImageFitMode, TextureSizeValues, ThreekitSource, Org } from '../types';

export const defaultThreekitEnv = 'preview';

// Environments with a different url than the typical *.threekit.dev
const appUrls: { [env: string]: string } = {
  preview: 'https://preview.threekit.com',
  rc: 'https://rc.threekit.com',
  fts: 'https://admin-fts.threekit.com',
};

export function apiRoot(env: string) {
  return (key: string) => {
    const appRoot = appUrls[env] || `https://${env}.threekit.dev`;
    return `${appRoot}/api`;
  };
}

export const defaultOrg: Org = {
  id: '',
  name: '',
  slug: '',
  members: [],
  // this needs to match services/users-service/src/models/featureflags.ts
  features: {
    vrayRendering: true,
    webGLRendering: true,
    approvalWorkflow: false,
    productsLimit: 100,
    assetsLimit: 1000,
    usersLimit: 10,
    publicShare: false,
    webglRoughness: 'gltf2',
    jobPriority: 0,
    rapidCompactCompressionLimit: 5000,
    dwgImportLimit: 5000,
    rapidCompactCompressionHost: 'api.rapidcompact.com',
    renderHoursLimit: 5000,
    webglRenderMode: 'cpu',
    vrayVersion: '5',
    showWarnings: true,
  },
  playerSettings: {
    zoomEnabled: true,
    helpButton: true,
    defaultPlayerMode: 'webgl',
    //
    imageFormat: 'png',
    imageResolution: '',
    imageQuality: 100,
    //
    zoomImageFormat: 'png',
    zoomImageResolution: '',
    zoomImageQuality: 100,
    zoomImageLoadingDelay: 2,
    //
    imageFitMode: ImageFitMode.FitHeight,
    //
    webglSize: TextureSizeValues.p4k,
    arSize: TextureSizeValues.p4k,
    vraySize: TextureSizeValues.Original,
    //
    skipEvalInvisibleNodes: false,
  },
  userId: '',
  profile: {},
  createdAt: new Date(),
  invites: [],
  tokens: [],
  tokensCount: 0,
  languages: {
    defaultValue: 'en',
    values: [{ label: 'English', value: 'en' }],
  },
  clientId: '',
  supportAccessEndOn: undefined,
};

interface ThreekitSourceContext {
  source: ThreekitSource;
  org: Org;
}

const defaultContext: ThreekitSourceContext = {
  source: {
    httpClient: axios.create({}),
    env: defaultThreekitEnv,
    apiRoot: apiRoot(defaultThreekitEnv),
  },
  org: defaultOrg,
};
const ApiContext = React.createContext(defaultContext);

export default ApiContext;

export function createSource(env: string, authToken: string): ThreekitSource {
  const httpClient = axios.create({});
  httpClient.interceptors.request.use((config) => {
    const char = config.url?.match(/\?/) ? '&' : '?';
    config.url += `${char}bearer_token=${authToken}`;
    return config;
  });
  return { httpClient, env, apiRoot: apiRoot(env) };
}
