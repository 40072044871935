import _ from 'lodash';
import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import Asset from '../Asset';
import NodeOrAsset from '../NodeOrAsset';
import { EvalNodeProps } from '../Node';
import { EvaluatedMaterialNode, MaterialReferencePrimitive } from '../../types';

interface MaterialEvalNodeProps extends EvalNodeProps {
  evalNode: EvaluatedMaterialNode;
  overrides?: MaterialReferencePrimitive;
}

export default function Material(props: MaterialEvalNodeProps) {
  const { evalNode, assetState } = props;
  const ref = useRef();
  // const mat = node?.material;
  const node = evalNode.node;
  const material = evalNode.Material;

  if (!material) return null;

  const inputMaps = _.unionBy(
    material.maps,
    props.overrides?.maps ?? [],
    (map) => map.attachTo
  );

  const maps = inputMaps.map(({ ref, attachTo }, idx) => {
    const overrides =
      // @ts-ignore
      material.overrides && material.overrides[attachTo]
        ? // @ts-ignore
          { uvTransform: material.overrides[attachTo] }
        : null;

    // console.log('Mat', node.name, attachTo, overrides);
    return (
      <NodeOrAsset
        {...props}
        reference={ref}
        key={idx}
        attachTo={attachTo}
        overrides={overrides}
      />
    );
  });

  //console.log( "material", material );

  // TODO: the encoding for baseMap, emissive maps should be sRGB.  The lack of this is likely causing color issues.

  return (
    <meshPhysicalMaterial
      name={node.name}
      color={props.color || material.baseColor}
      aoMapIntensity={material.aoMapIntensity}
      transparent={material.opacity < 1.0} // TODO: or if the opacity map is defined!!!
      bumpScale={material.bumpScale}
      clearcoat={material.clearcoat}
      clearcoatRoughness={material.clearcoatRoughness}
      dithering={true}
      emissive={material.emissive}
      metalness={material.metalness}
      lightMapIntensity={material.lightMapIntensity}
      normalScale={material.normalScale}
      opacity={material.opacity}
      roughness={material.roughness}
      sheen={material.sheen}
      sheenRoughness={material.sheenRoughness}
      specularIntensity={material.specularIntensity}
      transmission={material.transmission}
      transparent={material.transparent}
    >
      {maps}
    </meshPhysicalMaterial>
  );
}
