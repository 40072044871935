import { ActionDefinition, ActionState, EvaluatedAsset } from '../types';
import findAttribute from '../sceneGraph/findAttribute';

const setAttributeValue: ActionDefinition = {
  supports: ['item', 'material', 'model', 'scene', 'texture', 'composite'],
  label: 'Set Attribute Value',
  schema: {
    attributeId: {
      type: 'Attribute',
      label: 'Attribute',
      acceptArrayValue: true,
    },
    tableQuery: { type: 'Object', label: 'Datatable' },
    metadataQuery: { type: 'Object', label: 'Metadata Query' },
    value: { type: 'AttributeProperty', label: 'Value', from: 'attributeId' },
  },
  update: (
    input: ActionState,
    evalAsset: EvaluatedAsset,
    { attributeId, value, _vars }: any
  ) => {
    if (!attributeId) {
      return input;
    }
    const { attr } = findAttribute(evalAsset.attributes, attributeId);
    if (!attr) return input;
    return {
      ...input,
      configuration: { ...input.configuration, ...{ [attr.name]: value } },
    };

    // throw new Error('FIXME');
    // const { attrId, attrIndex } = readAttributeId(attributeId);
    // const attributes = configurator.getAttributes();
    // const attribute = attributes.find(({ id }) => id === attrId);

    // if (!attribute) return;
    // const hasIndex = attrIndex != null && !isNaN(Number(attrIndex));
    // const configuration = configurator.getConfiguration();

    // // FIXME: huh?
    // // const refs = store.getIn(['assetState', 'assetReferences']);
    // // const reverseId = refs?.reverse[configurator.assetId];
    // // if (reverseId === configurator.player?.assetId) {
    // //   const sourceAttr = attributes.find(({ id }) => id === _vars?.value);
    // //   if (sourceAttr) value = configuration[sourceAttr.name];
    // // }

    // const attrValue = configuration[attribute.name];
    // let curValue = hasIndex ? attrValue[Number(attrIndex)] : attrValue;
    // const isSameValue = JSON.stringify(value) === JSON.stringify(curValue);
    // const isOutOfBounds = attrIndex != null && attrIndex >= attrValue.length;

    // if (isSameValue || isOutOfBounds) {
    //   return;
    // }

    // let newValue;

    // if (hasIndex) {
    //   newValue = [...attrValue];
    //   newValue.splice(Number(attrIndex), 1, value);
    // } else {
    //   newValue = value;
    // }
    // return configurator.setConfiguration(
    //   { [attribute.name]: newValue },
    //   { skipNotification: true }
    // );
  },
};
export default setAttributeValue;
