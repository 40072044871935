import {
  Attribute,
  AttributeType,
  AssetState,
  Comparator,
  Condition,
  ConfigurationType,
  EvaluatedAsset,
} from '../types';
import findAttribute from './findAttribute';

// Check if the conditinos match the current input configuration.
// Returns true only if all conditions match.
export default function checkConditions(
  state: AssetState,
  evalAsset: EvaluatedAsset,
  conditions: Condition[],
  conf: ConfigurationType
): Boolean {
  const { attributes, condOperon } = evalAsset;
  for (const condition of conditions) {
    let match = true;
    const { attributeId, comparator, value } = condition;
    if (comparator === 'item') {
      console.warn('TODO: Item comparator');
      // const currentValue = this.player?.assetId;
      // const compareFn = compareFns[comparator] as Function;
      // match = await compareFn(currentValue, value);
      // if (!match) return false;
      continue;
    }

    if (comparator === 'composite') {
      console.warn('TODO: composite comparator');
      // const comaraFn = compareFns[comparator] as Function;
      // match = await comaraFn(this.player, value);
      // if (!match) return false;
      continue;
    }

    const { attr, attrIndex } = findAttribute(attributes, attributeId);

    if (!attr) return false;

    if (!comparator) continue;

    // console.log('check', attr, conf);

    const ofType = (attr.of ?? attr).type;

    const availableComparators = getAvailableComparators(ofType);

    if (!availableComparators.includes(comparator)) {
      throw new Error(
        `Invalid comparator (${comparator}) for attribute of type (${attr.type}).`
      );
    }

    const prop = condOperon.getProp(conf, attr.name) || null;

    let condValue = prop ? prop.set(value) : value;

    const compareFn = compareFns[comparator];
    let attrValue = conf[attr.name];

    if (attr.maxLength != null) {
      attrValue = attrValue.slice(0, attr.maxLength);
    }

    // console.log('check', value, condValue, attr, conf);

    const checkCondition = (attrValue: any, idx?: number) => {
      let condVal = condValue;
      if (comparator === 'changed') {
        console.warn('TODO: changed comparator');
        return false;
        // condVal = this.previousConfiguration![attr.name];
        // if (idx != null) {
        //   condVal = condVal[idx];
        // }
      }
      let condEqual = prop?.equals || ((a: any, b: any) => a == b);

      // console.log('check?', attrValue, condVal);
      const match = compareFn(attrValue, condVal, condEqual, attr);
      return match;
    };
    // console.log('compare', condValue, prop, attrValue);

    if (attrIndex != null) {
      if (attrIndex === 'any') {
        console.warn('TODO: `any` condition check');
        // match = await (attrValue as any[])
        //   .map((val, idx) => () => checkCondition(val, idx))
        //   .reduce(
        //     async (acc, nextCheck) => (await acc) || (await nextCheck()),
        //     Promise.resolve(false)
        //   );
      } else if (attrIndex === 'all') {
        console.warn('TODO: `all` condition check');
        // match = await (attrValue as any[])
        //   .map((val, idx) => () => checkCondition(val, idx))
        //   .reduce(
        //     async (acc, nextCheck) => (await acc) && (await nextCheck()),
        //     Promise.resolve(true)
        //   );
      } else {
        const idx = Number(attrIndex);
        if (idx >= attrValue.length) return false;
        console.log('check condition?', idx);
        // match = await checkCondition(attrValue[idx], idx);
      }
    } else {
      match = checkCondition(attrValue);
      // console.log('check condition, no index', match);
    }
    if (!match) return false;
  }
  return true;
}

/* tslint:disable:triple-equals */
const compareFns: {
  [key in Comparator]: (
    currentValue: any,
    input: any,
    equals: (a: any, b: any) => boolean,
    attribute: Attribute
  ) => boolean;
} = {
  '==': (currentValue, input, equals, attr) => {
    if ((attr.of ?? attr).type !== 'Asset' || !Array.isArray(input)) {
      return equals(currentValue, input);
    }
    // const values = await resolveTags(store, input, {
    //   orgId: configurator.inputs.orgId,
    // });
    // for (const i in values) {
    //   if (equals(currentValue, values[i])) return true;
    // }
    return false;
  },
  '!=': (currentValue, input, equals, attr) => {
    if ((attr.of ?? attr).type !== 'Asset' || !Array.isArray(input)) {
      return !equals(currentValue, input);
    }
    // const values = await resolveTags(store, input, {
    //   orgId: configurator.inputs.orgId,
    // });
    // for (const i in values) {
    //   if (equals(currentValue, values[i])) return false;
    // }
    return true;
  },
  '>': (currentValue: number, input: number) => currentValue > input,
  '<': (currentValue: number, input: number) => currentValue < input,
  '<=': (currentValue: number, input: number) => currentValue <= input,
  '>=': (currentValue: number, input: number) => currentValue >= input,
  includes: (currentValue: string, input: string) =>
    currentValue.includes(input),
  excludes: (currentValue: string, input: string) =>
    !currentValue.includes(input),
  metadata: (currentValue, input, equals, attr) => {
    return false;
    // if ((attr.of ?? attr)?.type !== 'Asset') return false;
    // const asset = await fetchAsset(
    //   store,
    //   currentValue,
    //   configurator.inputs.orgId
    // );
    // if (!asset) return false;
    // const metadata = asset.metadata;
    // const conditions = input.conditions || [];
    // if (input.assetType === 'item' && asset.type !== 'item') return false;
    // if (input.assetType === 'asset' && asset.type === 'item') return false;
    // for (const condition of conditions) {
    //   let { key, comparator, value, attributeId } = condition;
    //   if (attributeId && configurator) {
    //     const attributes = configurator.getAttributes();
    //     if (Array.isArray(attributeId)) {
    //       // TODO: Implement this
    //       console.warn('Array attributes in metadata not implemented');
    //       return false;
    //     }
    //     const attribute = attributes.find((attr) => attr.id === attributeId);
    //     value =
    //       attribute != null
    //         ? configurator.getConfiguration()[attribute.name]
    //         : undefined;
    //   }
    //   const metaValue = metadata[key];
    //   if (metaValue?.toString() === value && comparator === '!=') return false;
    //   if (metaValue?.toString() !== value && comparator === '=') return false;
    // }
    // return true;
  },
  item: (currentValue, input) => {
    return currentValue === input;
  },
  composite: (currentValue, input) => {
    const matchComposite =
      currentValue && currentValue.compositeId === input?.compositeId;
    const matchLayer = currentValue && currentValue.layerId === input?.layerId;
    return matchComposite && matchLayer;
  },
  changed: (currentValue, input, equals) => !equals(currentValue, input),
};
/* tslint:enable:triple-equals */

export function getAvailableComparators(type: AttributeType): Comparator[] {
  const comparators: Comparator[] = ['==', '!='];
  if (type === 'Number') {
    return [...comparators, '<', '>', '<=', '>=', 'changed'];
  } else if (type === 'String') {
    return [...comparators, 'includes', 'excludes', 'changed'];
  } else if (type === 'Asset') {
    return [...comparators, 'metadata', 'changed'];
  }
  return [...comparators, 'changed'];
}

export const comparators = Object.keys(compareFns);
