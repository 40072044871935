import isUuid from 'is-uuid';
import {
  Attribute,
  AssetState,
  AttributeStateMap,
  Condition,
  ConfigurationType,
  EvaluatedAction,
  EvaluatedAsset,
  EvaluatedRule,
  OperatorSchema,
  PropertySchema,
  Rule,
  SceneGraphNode,
  ThreekitSource,
} from '../types';
import _, { flatten, uniqBy, pick } from 'lodash';
import { suspend } from 'suspend-react';
import { lookupAction } from '../cas/lookups';
import { makeAttributeOperon } from '../cas/Operon';
import { AssetFindOptions, find } from '../queries/assets';
import findAttribute from './findAttribute';

const newAttributeState = () => ({
  visible: true,
  enabled: true,
  hiddenValues: [],
  disabledValues: [],
});

interface AttributeValueMap {
  [id: string]: {
    value: any;
    label: string;
    enabled: Boolean;
    visible: Boolean;
  }[];
}

function getInitialAttributeStateMap(
  attributes: Attribute[],
  valueMap: AttributeValueMap
) {
  return attributes.reduce((states: AttributeStateMap, attr: Attribute) => {
    states[attr.id] = newAttributeState();
    if (valueMap[attr.id]) states[attr.id].values = valueMap[attr.id];
    if (attr.type === 'String') {
      states[attr.id].values = attr.values.map((val: string) => ({
        value: val,
        label: val,
        enabled: true,
        visible: true,
      }));
    }
    return states;
  }, {});
}

// Given a root node, return an EvaluatedAsset.
// Returns false if no configurator is available
export default function evaluateAsset(
  node: SceneGraphNode,
  source: ThreekitSource,
  resolveAttributes?: boolean
): {
  evaluatedAsset: EvaluatedAsset | false;
  attributeStateMap: AttributeStateMap;
} {
  if (!node.configurator) {
    return { evaluatedAsset: false, attributeStateMap: {} };
  }

  // TODO: load global attributes here, with a loader

  const attributes = node.configurator.attributes ?? [];

  const rules = (node.configurator.rules ?? []).map((rule) => {
    return {
      rule,
      actions: rule.actions
        .map((action) => {
          const operon = lookupAction('', action.type);
          if (!operon) {
            console.warn(`Unkown action ${action.type}`);
          }
          return {
            action,
            operon,
          };
        })
        .filter((v) => !!v.operon),
    };
  });

  const condOperon = makeConditionsOperatorFrom(
    attributes,
    node.configurator.rules,
    node.name
  );
  if (!attributes.length && !rules.length) {
    return { evaluatedAsset: false, attributeStateMap: {} }; // this is a static asset, no configurator
  }

  const operon = makeAttributeOperon(
    {
      schema: attributes.reduce((acc: OperatorSchema, attr) => {
        acc[attr.name] = attr as PropertySchema;
        return acc;
      }, {}),
    },
    `Configurator ${node.name}`
  );
  const defaultConfiguration = operon.init();

  const evaluatedAsset = {
    attributes,
    rules,
    operon,
    condOperon,
    defaultConfiguration,
  };

  const attrValues = resolveAttributes
    ? resolveAssetAttributeValues(node, source)
    : {};

  return {
    evaluatedAsset,
    attributeStateMap: getInitialAttributeStateMap(attributes, attrValues),
  };
}

// Make an operon from all attributes referenced by conditions, but with
// defaultValue set to the base default
function makeConditionsOperatorFrom(
  attributes: Attribute[],
  rules: Rule[],
  name: string
) {
  // Collect all the attributes used by all conditions
  const usedAttributes = _(rules)
    .flatMap((rule) => rule.conditions)
    .map(({ attributeId }) => findAttribute(attributes, attributeId).attr)
    .compact();

  const schema = usedAttributes.reduce((opSchema: OperatorSchema, attr) => {
    const attrSchema = attr as PropertySchema;
    const ofType = (attr.of ?? attr).type;
    const ruleType: PropertySchema['type'] =
      ofType === 'Asset' ? 'DefaultsEqualAsset' : ofType;

    //   // Create a schema with the default "defaultValue", otherwise null condition values would be set to the attribute's default value.
    const ruleValueSchema = {
      ...attrSchema,
      type: ruleType,
      defaultValue: undefined,
    } as PropertySchema;
    opSchema[attr.name] = ruleValueSchema;
    return opSchema;
  }, {});
  // console.log('used attrs?', usedAttributes.value(), schema);

  return makeAttributeOperon({ schema }, `Configurator ${name} conditions`);
}

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function resolveAssetAttributeValues(
  node: SceneGraphNode,
  source: ThreekitSource
) {
  // const node = assetState.nodes[assetState.id];
  const attributes =
    node.configurator?.attributes.filter((attr) => attr.type === 'Asset') ?? [];

  const attrValueMap: AttributeValueMap = {};

  // console.log('resolve asset values', attributes);

  attributes.forEach((attr: Attribute) => {
    let values = attr.values || [];
    let type = attr.of?.type ?? attr.type;
    if (values.length) {
      const resolvedValues = resolveAssets(source, values);
      // console.log('resolved', resolvedValues, 'for', attr);
      attrValueMap[attr.id] = resolvedValues.map((rv) => {
        return {
          ...rv,
          enabled: true,
          visible: true,
          label: rv.name || '',
          value: { assetId: rv.assetId },
        };
      });
    }
  });
  return attrValueMap;
}

// Given a list of asset references, resolve them into fetched asset models.
// refs here might be:
//    1. '#tag'.
//    2. uuid
//    3. ['multiple','tags']?
//    4. { assetId }
// This is a suspend aware api, so it will throw a promise around the fetch
export function resolveAssets(source: ThreekitSource, refs: any[]) {
  const allAssets = refs.map((ref: { assetId?: string } | string) => {
    const query: AssetFindOptions = { type: 'item' };

    if (typeof ref === 'string') {
      if (ref[0] === '#') {
        query.tags = [ref.slice(1)];
      } else if (isUuid.anyNonNil(ref)) {
        query.id = ref;
      } else {
        console.warn('Non tag asset reference', ref);
        return []; // non tag asset reference, bad data, just ignore it
      }
    } else if (Array.isArray(ref)) {
      query.tags = ref;
    } else {
      const assetId = ref.assetId;
      if (!assetId) return [];
      if (isUuid.anyNonNil(assetId)) {
        query.id = assetId;
      } else {
        throw new Error('Invalid assetId reference: ' + assetId);
      }
    }

    const response = suspend(
      async () => find(source, query),
      [JSON.stringify(query)]
    );

    // console.log('resolveAssets response', response, 'from', query, 'is', res);
    return response.assets.map((asset) => {
      return {
        assetId: asset.id,
        ...pick(asset, ['name', 'tags', 'metadata']),
      };
    });
  });

  return uniqBy(flatten(allAssets), (a) => a.assetId);
}
