import React, { useEffect, useRef, useState, Component, Suspense } from 'react';
import { extend, useFrame, useThree } from '@react-three/fiber';
import lodash from 'lodash';
import { v4 as uuid } from 'uuid';
// import { StoreContext } from './StoreContext';
import Asset from './components/Asset';
import BinGeometry from './components/nodes/BinGeometry';
// import ThreekitSourceContext from './components/ThreekitSourceContext';
import ThreekitSource from './components/ThreekitSource';
import { addActions, addOperators } from './cas/lookups';
import { actionOperons } from './actions';
import operators from './operators';
addActions(actionOperons);
addOperators(operators);
export type { DisplayAttribute } from './types';
export * from './api';

extend({ BinGeometry });
declare global {
  namespace JSX {
    interface IntrinsicElements {
      binGeometry: { args: any };
    }
  }
}

function Spinner(props: any) {
  const ref = useRef();

  useFrame(({ clock }) => {
    if (ref && ref.current) {
      // @ts-ignore
      ref.current.rotation.x = clock.getElapsedTime();
      // @ts-ignore
      ref.current.rotation.y = clock.getElapsedTime();
    }
  });
  return (
    <mesh ref={ref}>
      <boxGeometry args={[1, 1, 1]} />
      <meshBasicMaterial color="#1D9368" />
    </mesh>
  );
}

export { Asset, Spinner, ThreekitSource };
