import {
  Color,
  Euler,
  Matrix4,
  Texture,
  Vector2,
  Vector3,
  Quaternion,
} from 'three';
import { AxiosInstance, AxiosStatic } from 'axios';
import type {
  PropertySchema,
  PropertySchemaInstance,
  SchemaType,
} from './cas/schemaTypes';
import { PolyMesh } from './polyMesh';
import { PhysicalMaterialPlug } from './operators/Material/MaterialPlug';
import { ImagePlug } from './operators/Image/ImagePlug';
// import type { Operator } from './cas/makeOperator';
import type { ActionOperon, OperatorOperon, Operon } from './cas/Operon';
import type { AssetFindOptions } from './queries/assets';
import type { AttributeType } from './cas/attributes';

interface Objects {
  [hash: string]: string;
}

// interface Committing {
//   promise: DeferredPromise<string | undefined>;
//   HEAD?: string;
//   INDEX?: string;
//   commit?: Commit;
//   undoing?: boolean;
// }

export {
  ActionOperon,
  AttributeType,
  OperatorOperon,
  Operon,
  PropertySchema,
  PropertySchemaInstance,
  SchemaType,
}; // re-export from here

export interface Refs {
  HEAD: string;
  INDEX?: string;
  ORIGIN?: string;
  UNDO?: string;
  REDO: string[];
  // committing: Committing[];
}

type RefKeyType = 'HEAD' | 'INDEX' | 'ORIGIN';

interface ObjectBucket {
  [key: string]: any;
}

interface ToReferences {
  [refKey: string]: string | string[];
}

export interface CasInputs {
  id: string;
  type?: string;
  index?: string;
  orgId?: string;
  playerId?: string;
}

export type Path = any[];

export interface SceneGraphAction {
  [key: string]: any;
}

export interface SceneGraphOperator {
  [key: string]: any;
}

export interface SceneGraphPlugs {
  [plug: string]: SceneGraphOperator[];
}

export type Comparator =
  | '=='
  | '!='
  | '>'
  | '<'
  | '<='
  | '>='
  | 'includes'
  | 'excludes'
  | 'metadata'
  | 'item'
  | 'composite'
  | 'changed';

export interface ConfigurationType {
  [name: string]: any;
} // Map<string, any>;

export interface Attribute {
  type: AttributeType;
  name: string;
  [key: string]: any;
}

export interface AttributesMap {
  [id: string]: Attribute;
}

export interface AttributeStateValue {
  value: any;
  label: string;
  enabled: Boolean;
  visible: Boolean;
  assetId?: string; // extra asset info attached
  tags?: string[]; // extra asset info attached
}

export interface AttributeState {
  visible: Boolean;
  enabled: Boolean;
  values?: AttributeStateValue[];
  hiddenValues: string[];
  disabledValues: string[];
}

export interface AttributeStateMap {
  [id: string]: AttributeState;
}

interface AttributeValue {
  value: any;
}

export type DisplayAttribute = Attribute & AttributeState & AttributeValue;

// onAttributes hook
export type OnAttributes = (attributes: DisplayAttribute[]) => void;

export type AttributeId = string | string[];

export interface Condition {
  attributeId?: AttributeId;
  comparator?: Comparator;
  value?: any;
}

interface AttributeMap {
  [key: string]: undefined | AttributeId;
}

export interface Action {
  type: string;
  _vars?: AttributeMap;
  [key: string]: any;
}

export type ActionProperty = keyof Action;

export interface Rule {
  conditions: Condition[];
  actions: Action[];
  name: string;
  disabled?: boolean;
}

export interface ConfiguratorNode {
  attributes: Attribute[];
  metadata?: Attribute[];
  rules: Rule[];
  script?: string;
}

interface DefaultOperators {
  [plug: string]: string;
}

const sceneGraphNodeTypesArray = [
  'Camera',
  'Image',
  'Item',
  'Light',
  'Material',
  'MaterialLibrary',
  'Model',
  'Null',
  'Objects',
  'PolyMesh',
  'Scene',
  'Stage',
] as const;
//   'Scene',
//   'MaterialLibrary',
//   'Light',
//   'Camera',
//   'Model',
//   'Pass',
//   'Renderer',
//   'Bone',
//   'Annotation',
//   'Measurement',
//   'Sprite',
//   'Shape',
//   'Helper',
//   'Vrscene',
//   'Item',
//   'Upload',
//   'Group',
//   'Layer',
//   'ImageLayer',
//   'Composite',
//   'ShadowPlane',
//   'Vector',
//   'Font',
//   'Video',
//   'Lut',
//   'Vfb',
//   'Stage',
//   'VrayMesh',
//   'VrayLight',
//   'LayoutContainer',
// ] as const;

// Define valid sceneGraph node types as a union type from an iterable array
// This way we have both a union type, and an Array to iterate over in code
export type SceneGraphNodeType = typeof sceneGraphNodeTypesArray[number];

const PlugTypes = [
  'Image',
  'Material',
  'Null',
  'Objects',
  'PolyMesh',
  'Properties',
  'Proxy',
  'Transform',
] as const;
export type SceneGraphPlugType = typeof PlugTypes[number];

const PolyMeshNodePlugs = ['PolyMesh', 'Transform'];

// only used to create nodes?
export const nodeShapes: { [type in SceneGraphNodeType]: DefaultOperators } = {
  Camera: {
    // Camera: 'Camera',
    // Transform: 'Transform',
    // Properties: 'Default',
  },
  Image: {
    Image: 'Image',
    Properties: 'ImageProperties',
  },
  Item: {
    Proxy: 'Proxy',
  },
  Light: {
    // Light: 'PointLight',
    Transform: 'Transform',
    // Properties: 'Default',
  },
  Material: {
    Material: 'Physical',
    Properties: 'MaterialProperties',
  },
  MaterialLibrary: {},
  Model: {
    Null: 'Model',
    Transform: 'Transform',
    Mixer: 'Mixer',
    Properties: 'ModelProperties',
  },
  Null: {
    Null: 'Null',
    Transform: 'Transform',
    Properties: 'Default',
  },
  Objects: {
    Objects: 'Objects',
  },
  PolyMesh: {
    PolyMesh: 'Box',
    Transform: 'Transform',
    Material: 'Reference',
    Properties: 'PolyMeshProperties',
  },
  Scene: {
    //   Environment: 'Environment',
    //   Timeline: 'Timeline',
    //   Player: 'Player',
    //   PostEffect: 'PostEffect',
  },
  Stage: {
    Proxy: 'Proxy',
  },
  // //
  // //
  // Pass: {
  //   Pass: 'Pass',
  // },
  // //
  // Renderer: {
  //   Renderer: 'WebGLRenderer',
  // },
  // Bone: {
  //   Bone: 'Bone',
  //   Transform: 'BoneTransform',
  //   Properties: 'Default',
  // },
  // //
  // Annotation: {
  //   Annotation: 'Annotation',
  //   Transform: 'Transform',
  //   Properties: 'Default',
  // },
  // Measurement: {
  //   Measurement: 'Ruler',
  //   Properties: 'Default',
  // },
  // //
  // Sprite: {
  //   Sprite: 'Sprite',
  //   Transform: 'Transform',
  //   Properties: 'Default',
  // },
  // //
  // Shape: {
  //   Shape: 'Rectangle',
  //   Transform: 'Transform',
  //   Material: 'Reference',
  //   Properties: 'Default',
  // },
  // Helper: {
  //   Null: 'Helper',
  //   Transform: 'Transform',
  //   Properties: 'Default',
  // },
  // Vrscene: {
  //   Vrscene: 'vrscene',
  //   Transform: 'Transform',
  //   Properties: 'Default',
  // },
  // Item: { Proxy: 'Proxy' },
  // Upload: { Proxy: 'Proxy' },
  // Group: {
  //   Group: 'Group',
  //   Properties: 'GroupProperties',
  // },
  // Layer: {
  //   Properties: 'LayerProperties',
  // },
  // ImageLayer: {
  //   Properties: 'ImageLayerProperties',
  // },
  // Composite: {
  //   Composite: 'Composite',
  // },
  // ShadowPlane: {
  //   ShadowPlane: 'ShadowPlane',
  //   Transform: 'Transform',
  //   Properties: 'Default',
  // },
  // Vector: {
  //   Vector: 'Vector',
  // },
  // Font: {
  //   Font: 'Font',
  // },
  // Video: {
  //   Video: 'Video',
  // },
  // Lut: {
  //   Lut: 'Lut',
  // },
  // Vfb: {
  //   Vfb: 'Vfb',
  // },
  // Stage: {
  //   Proxy: 'Proxy',
  // },
  // //
  // VrayMesh: {
  //   VrayMesh: 'VrayMesh',
  //   Transform: 'Transform',
  //   Material: 'VrayMeshReference',
  //   Properties: 'Default',
  // },
  // VrayLight: {
  //   VrayLight: 'VrayLight',
  //   Transform: 'Transform',
  //   Properties: 'Default',
  // },
  // //
  // LayoutContainer: {
  //   LayoutContainer: 'MeshLayoutContainer',
  //   Selection: 'Selection',
  //   Properties: 'Default',
  // },
};

export interface SceneGraphNode {
  id: string;
  name: string;
  type: SceneGraphNodeType;
  plugs: SceneGraphPlugs;
  configurator?: ConfiguratorNode;
  children: string[];
}

interface SceneGraphObjects {
  [hash: string]: string;
}

export interface SceneGraphNodeMap {
  [id: string]: SceneGraphNode;
}

export interface SceneGraphAttributeMap {
  [id: string]: Attribute;
}

export interface CasState {
  id: string;
  orgId?: string;
  objects: Objects;
  refs: Refs;
  commits: { [hash: string]: any }; // make a Commit interface
  nodes: SceneGraphNodeMap;
  attributes: AttributesMap; // global attributes
}

export interface AssetState extends CasState {
  asset: AssetModel;
}

export interface AssetReference {
  assetId?: string;
  orgId?: string;
  configuration?: any;
  type?: string;
  query?: { metadata: { [key: string]: any } };
}

export type AssetOrNodeReference = AssetReference | string;

export interface FileAttrs {
  hash: string;
  filename: string;
  type: string;
  id?: string;
}

export type HttpClient = AxiosInstance | AxiosStatic;

//  Contains all information needed to intermediate between different
//  environments. Each source would correspond to an org/authtoken.
export interface ThreekitSource {
  env: string;
  httpClient: HttpClient;
  apiRoot: (key?: string) => string;
}

// Data defined by the initialization of the headless api
export interface ThreekitApiData {
  assetState: AssetState;
  configuration: ConfigurationType;
  source: ThreekitSource;
}
export type ThreekitApiAddAsset = (
  data: ThreekitApiData,
  parentId?: string
) => void;

export interface ThreekitApiOptions {
  skipFiles: boolean;
  addAsset: ThreekitApiAddAsset;
}

export interface ThreekitSceneApi {
  get: (state: AssetState, path: Path) => any;
  set: (state: AssetState, path: Path, value: any) => any;
}
export interface ThreekitQueriesApi {
  assets: {
    find: (query: AssetFindOptions) => Promise<AssetModel[]>;
    get: (id: string) => Promise<AssetModel>;
  };
}
export interface ThreekitApi {
  scene: ThreekitSceneApi;
  queriesFor: (source: ThreekitSource) => ThreekitQueriesApi;
}

export interface ThreekitHeadlessApi {
  evaluateAsset: () => Promise<void>;
  unmount: () => Promise<void>;
  toGraph: Function;
}

export interface DeferredPromise<T> extends Promise<T> {
  resolve: Function;
  rejected: boolean;
  pending: boolean;
  fulfilled: boolean;
}

export interface AssetModel {
  id: string;
  name: string;
  description?: string;
  tags?: string[];
  keywords?: string[];
  metadata: { [key: string]: any };
  defaultStageId?: string;
}

export interface BasePrimitive {
  readonly type: SceneGraphPlugType;
}

export interface TransformPrimitive extends BasePrimitive {
  readonly type: 'Transform';
  transform: Matrix4;
  position: Vector3;
  rotation: Quaternion;
  euler: Euler;
  scale: Vector3;
}

// export interface ImagePlug extends BasePrimitive {
//   readonly type: 'Image';
// }

// export interface MaterialPrimitive extends BasePrimitive {
//   readonly type: 'Material';
// }

interface PolyMeshGeometry {
  kind: 'Box' | 'Sphere';
  args: any;
}

export interface ImagePrimitive {
  readonly type: 'Image';
  texture: Texture;
}

export interface PolyMeshPrimitive extends BasePrimitive {
  readonly type: 'PolyMesh';
  mesh?: PolyMesh;
  geometry?: PolyMeshGeometry;
}

export interface MaterialReferencePrimitive extends BasePrimitive {
  readonly type: 'Material';
  reference: AssetOrNodeReference;
  maps?: Array<MaterialMap>;
}

export interface MaterialMap {
  ref: AssetOrNodeReference;
  attachTo: string;
  uvTransform?: any;
}

export interface MaterialPrimitive {
  readonly type: 'Material';
  baseColor: Color;
  maps: Array<MaterialMap>;
  aoMapIntensity: number;
  bumpScale: number;
  clearcoat: number;
  clearcoatRoughness: number;
  emissive: Color;
  lightMapIntensity: number;
  metalness: number;
  normalScale: Vector2;
  opacity: number;
  roughness: number;
  sheen: number;
  sheenRoughness: number;
  specularIntensity: number;
  transmission: number;
  transparent: boolean;
  overrides: any; // do a better type here
}

export interface NullPrimitive {
  readonly type: 'Null';
  link?: AssetReference;
}

export interface PropertiesPrimitive {
  readonly type: 'Properties';
  visible: Boolean;
}

export interface ProxyPrimitive {
  readonly type: 'Proxy';
  link?: AssetReference;
}

export type PlugPrimitive =
  | ImagePrimitive
  | MaterialPrimitive
  | MaterialReferencePrimitive
  | NullPrimitive
  | PolyMeshPrimitive
  | PropertiesPrimitive
  | ProxyPrimitive
  | TransformPrimitive;

export type OperatorData = {
  [key: string]: any;
};

export interface OperatorSchema {
  [key: string]: PropertySchema;
}

export type OpUpdateFunction = (
  data: OperatorData,
  primitive: PlugPrimitive,
  options?: EvaluateOptions
) => PlugPrimitive;

export interface ActionState {
  assetState: AssetState;
  configuration: ConfigurationType;
  attributeStateMap: AttributeStateMap;
}

export type ActionUpdateFunction = (
  input: ActionState,
  evalAsset: EvaluatedAsset,
  attrs: OperatorData
) => ActionState;

export type UpdateFunction = OpUpdateFunction | ActionUpdateFunction;

// TODO: SupportedType is not a good name for this but it is a bit strange because it
// combines two things, namely asset type and node type (for composite).
export type SupportedType =
  | 'composite'
  | 'texture'
  | 'material'
  | 'model'
  | 'scene'
  | 'item'
  | 'vector';

interface BaseOperatorDefinition {
  label?: string;
  schema: OperatorSchema;
  update?: OpUpdateFunction | ActionUpdateFunction;
}

export interface OperatorDefinition extends BaseOperatorDefinition {
  deprecated?: string | true;
  update?: OpUpdateFunction;
  useVars?: boolean;
}

export interface ActionDefinition extends BaseOperatorDefinition {
  update: ActionUpdateFunction;
  useVars?: boolean;
  supports?: SupportedType[];
}

export type AllOperatorDefinition = OperatorDefinition | ActionDefinition;

// export const nodePrimitives: { [type in SceneGraphNodeType]: PlugPrimitive } = {
// }

export interface EvaluatedAction {
  action: Action;
  operon?: ActionOperon;
}

export interface EvaluatedRule {
  rule: Rule;
  actions: EvaluatedAction[];
}

export interface EvaluatedAsset {
  attributes: Attribute[];
  operon: Operon;
  condOperon: Operon;
  defaultConfiguration: ConfigurationType;
  metadata?: Attribute[];
  rules: EvaluatedRule[];
}

interface BaseEvaluatedNode {
  readonly type: SceneGraphNodeType;
  node: SceneGraphNode;
  // [plug: SceneGraphPlugType]: PlugPrimitive;
}

export interface EvaluatedCameraNode extends BaseEvaluatedNode {
  readonly type: 'Camera';
}

export interface EvaluatedImageNode extends BaseEvaluatedNode {
  readonly type: 'Image';
  Image: ImagePrimitive;
}

export interface EvaluatedItemNode extends BaseEvaluatedNode {
  readonly type: 'Item';
  Proxy: ProxyPrimitive;
}

export interface EvaluatedLightNode extends BaseEvaluatedNode {
  readonly type: 'Light';
}

export interface EvaluatedMaterialNode extends BaseEvaluatedNode {
  readonly type: 'Material';
  Material: MaterialPrimitive;
}

export interface EvaluatedMaterialLibraryNode extends BaseEvaluatedNode {
  readonly type: 'MaterialLibrary';
}

export interface EvaluatedModelNode extends BaseEvaluatedNode {
  readonly type: 'Model';
  Transform: TransformPrimitive; // every node should have at least a transform primitive
  Null: NullPrimitive;
  Properties: PropertiesPrimitive;
}

export interface EvaluatedNullNode extends BaseEvaluatedNode {
  readonly type: 'Null';
  Transform: TransformPrimitive; // every node should have at least a transform primitive
  Null: NullPrimitive;
  Properties: PropertiesPrimitive;
}

export interface EvaluatedObjectsNode extends BaseEvaluatedNode {
  readonly type: 'Objects';
}

export interface EvaluatedPolyMeshNode extends BaseEvaluatedNode {
  readonly type: 'PolyMesh';
  PolyMesh: PolyMeshPrimitive;
  Transform: TransformPrimitive; // every node should have at least a transform primitive
  Material: MaterialReferencePrimitive;
  Properties: PropertiesPrimitive;
}

export interface EvaluatedSceneNode extends BaseEvaluatedNode {
  readonly type: 'Scene';
}

export interface EvaluatedStageNode extends BaseEvaluatedNode {
  readonly type: 'Stage';
  Proxy: ProxyPrimitive;
}

export type EvaluatedNode =
  | EvaluatedCameraNode
  | EvaluatedImageNode
  | EvaluatedItemNode
  | EvaluatedLightNode
  | EvaluatedMaterialNode
  | EvaluatedMaterialLibraryNode
  | EvaluatedModelNode
  | EvaluatedNullNode
  | EvaluatedObjectsNode
  | EvaluatedPolyMeshNode
  | EvaluatedSceneNode
  | EvaluatedStageNode;

export interface EvaluateOptions {
  source: ThreekitSource;
  skipFiles: boolean;
  org: Org;
}

export interface DefaultStages {
  model: string;
  material: string;
  texture: string;
  [key: string]: string;
}

export type PlayerMode = 'webgl' | 'image';

export enum TextureSizeValues {
  Organization = 1,
  Original = 2,
  p512 = 512,
  p1k = 1024,
  p2k = 2048,
  p4k = 4096,
}

export enum ImageFitMode {
  FitWidth = 'fitWidth',
  FitHeight = 'fitHeight',
  Cover = 'cover',
  Contain = 'contain',
}

export interface ImageUrlOptions {
  format?: string;
  quality?: number;
  width?: string;
  height?: string;
  power2?: number;
}

export interface OrgPlayerSettings {
  arButton?: boolean;
  fullScreenButton?: boolean;
  logo?: boolean;
  shareButton?: boolean;
  zoomEnabled?: boolean;
  helpButton?: boolean;
  defaultStages?: DefaultStages;
  defaultPlayerMode?: PlayerMode;
  //
  imageFormat?: string;
  imageResolution?: string;
  imageQuality?: number;
  //
  webglSize?: TextureSizeValues;
  arSize?: TextureSizeValues;
  vraySize?: TextureSizeValues;
  //
  zoomImageFormat?: string;
  zoomImageResolution?: string;
  zoomImageQuality?: number;
  zoomImageLoadingDelay?: number;
  //
  imageFitMode?: ImageFitMode;
  //
  skipEvalInvisibleNodes?: boolean;
}

export interface LanguageSettings {
  defaultValue: string;
  values: Array<{ label: string; value: string }>;
}

export interface Org {
  id: string;
  name: string;
  slug: string;
  features: OrgFeatures;
  playerSettings: OrgPlayerSettings;
  members: any;
  userId: string;
  createdAt: Date;
  invites: Array<string>;
  tokens: Array<string>;
  tokensCount?: number;
  profile: OrgProfile;
  languages: LanguageSettings;
  clientId?: string;
  supportAccessEndOn?: Date;
}

export interface OrgProfile {
  logo?: string;
  descriptionShort?: string;
  descriptionLong?: string;
  website?: string;
  contactEmail?: string;
  [key: string]: any;
}

export interface OrgFeatures {
  showWarnings?: boolean;
  vrayRendering?: boolean;
  webGLRendering?: boolean;
  publicShare?: boolean;
  productConfiguration?: ProductConfigurations;
  approvalWorkflow?: boolean;
  productsLimit?: number;
  assetsLimit?: number;
  usersLimit?: number;
  webglRoughness?: 'legacy' | 'gltf2';
  subscriptionStartDate?: Date;
  rapidCompactCompression?: boolean;
  rapidCompactCompressionHost?: string;
  rapidCompactCompressionToken?: string;
  dwgImport?: boolean;
  rapidCompactCompressionLimit?: number;
  dwgImportLimit?: number;
  renderHoursLimit?: number;
  jobPriority?: number;
  vrayVersion?: '4' | '5';
  [key: string]: any;
}

export enum ProductConfigurations {
  none = 'none',
  simple = 'simple',
  world = 'advanced',
}

export interface QueryResponse {
  count: number;
  page: number;
  perPage: number;
  sort?: string; // is sort always returned?
}
