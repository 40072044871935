import { ActionDefinition, ActionState, EvaluatedAsset } from '../types';
import { processValues } from './setAttributeValueVisibility';

const setAttributeValueEnabled: ActionDefinition = {
  supports: ['item', 'material', 'model', 'scene', 'texture'],
  label: 'Set Attribute Value Disabled',
  schema: {
    attributeId: {
      type: 'Attribute',
      label: 'Attribute',
      filterBy: 'values',
    },
    values: { type: 'AttributeValues', label: 'Values', from: 'attributeId' }, // values: { type: 'AttributeValues', label: 'Values', from 'attributeId' }
    enabled: { type: 'Boolean', label: 'Enabled' },
  },
  update: (
    input: ActionState,
    evalAsset: EvaluatedAsset,
    args: { attributeId: string; values: any; enabled: Boolean }
  ) => {
    const { attributeStateMap, assetState } = input;
    const { attributeId, enabled } = args;
    if (!attributeId || !args.values || !args.values.length) return input;
    const attrState = input.attributeStateMap[attributeId];
    if (!attrState) {
      throw new Error('Unknown attribute state for ' + attributeId);
    }

    const attr = assetState.nodes[assetState.id].configurator?.attributes.find(
      (attr) => attr.id === attributeId
    );

    if (!attr) {
      throw new Error('Invalid attribute' + attributeId);
    }

    if (!attrState.values) {
      // The values may have not been resolved, if we didn't ask for a configurator
      // skip this action in this case, since it is UI sugar
      return input;
    }

    const values = processValues(
      'enabled',
      enabled,
      attr,
      [...attrState.values],
      args.values
    );
    // Create new hiddenValues array from values state
    const disabledValues = values.filter((v) => !v.enabled).map((v) => v.value);

    // console.warn(args, attrState, attr, result);
    return {
      ...input,
      attributeStateMap: {
        ...input.attributeStateMap,
        ...{ [attributeId]: { ...attrState, values, disabledValues } },
      },
    };
  },
};

export default setAttributeValueEnabled;
