import lodash from 'lodash';
const { mapValues } = lodash;
import {
  ActionDefinition,
  ActionState,
  AllOperatorDefinition,
  CasState,
  EvaluatedAsset,
  OperatorData,
  OperatorDefinition,
  OperatorSchema,
  PlugPrimitive,
  UpdateFunction,
} from '../types';
import {
  PropertySchema,
  PropertySchemaInstance,
  schemaTypes,
} from './schemaTypes';

interface OperatorReferences {
  dynamic: string[]; // requires operator data to determine what type of reference they are
  asset: string[];
  file: string[];
  node: string[];
  plug: string[];
  part: string[];
  custom: string[];
  keys: string[];
  export: string[];
  plugs: { [key: string]: string };
  all: string[];
}

const initRefs = () => ({
  all: [],
  dynamic: [],
  asset: [],
  file: [],
  node: [],
  plug: [],
  part: [],
  custom: [],
  keys: [],
  export: [],
  plugs: {},
});

interface OperatorSchemaDefinition {
  schema: OperatorSchema;
  update: Function;
}

export class Operon {
  public label: string;
  public name: string;

  public def: AllOperatorDefinition;

  private prop: PropertySchemaInstance;
  private references: OperatorReferences;
  private props: { [key: string]: PropertySchemaInstance };
  private exportReferences: { [key: string]: boolean } = {
    file: true,
    part: true,
    asset: true,
  };
  private useVars: boolean = false;

  constructor(def: AllOperatorDefinition, name: string) {
    this.def = def;
    if (def.useVars) this.useVars = true;

    this.initProps({});
    this.name = name;
  }

  public initProps(data: OperatorData) {
    this.references = initRefs();
    this.props = mapValues(
      this.def.schema,
      this.initProp.bind(this, this.references)
    );
  }

  public initProp(refs: OperatorReferences, info: PropertySchema, key: string) {
    if (!schemaTypes[info.type]) {
      throw new Error(`No schema type for ${info.type}`);
    }

    this.prop = schemaTypes[info.type](info);
    if (info.of) {
      // subschema for `of`.
      this.prop.of = schemaTypes[info.of.type](info.of);
    }

    if (this.prop.isCustom) refs.custom.push(key);
    if (this.prop.references) {
      refs[this.prop.references].push(key);
    }
    if (this.prop.referenceFor) {
      refs.dynamic.push(key);
    }
    if (this.prop.plug) refs.plugs[key] = this.prop.plug;

    if (!this.prop.references) {
      if (!this.prop.referenceFor) refs.export.push(key);
    } else if (this.exportReferences[this.prop.references]) {
      refs.export.push(key);
    }

    refs.all.push(key);

    return this.prop;
  }

  public init(initAttrs: OperatorData = {}, type?: string, name?: string) {
    const result: OperatorData = {};

    if (initAttrs.id) {
      // Not all operators have IDs. Configurators do no need ID.
      result.id = initAttrs.id;
    }

    if (type) {
      result.type = type;
    }
    const refs = this.getRefs(initAttrs);
    for (const key of refs.all) {
      const prop = this.getProp(initAttrs, key);
      if (prop) result[key] = prop.set(initAttrs[key]);
    }

    // If "name" is not an operator property, get if from the init parameters.
    if (!result.name) {
      // Avoid setting `name: undefined`, since it can mess with exact comparisons.
      if (name || type) {
        result.name = name || type;
      }
    }

    if (this.useVars && initAttrs._vars) {
      result._vars = initAttrs._vars;
    }
    // result._v = 1;
    return result;
  }

  public equals(a: any, b: any) {
    const { equals } = this.prop;
    if (!equals) {
      return a === b;
    }

    return equals(a, b);
  }

  public set(data: OperatorData, key: string, val: any) {
    const prop = this.props[key] || this.getProp(data, key);
    if (!prop) {
      data[key] = val;
      return true;
    }

    const newVal = prop.set(val);
    const isChanged = prop.equals
      ? !prop.equals(newVal, data[key])
      : newVal !== data[key];
    if (isChanged) {
      data[key] = prop.get ? prop.get(newVal) : newVal;
    }
    return isChanged;
  }

  public getSchema(data: OperatorData = {}, key: string) {
    if (this.def.schema[key]) {
      return this.def.schema[key];
    }
    const k = this.references.custom.find(
      (k: string) => data[k] && data[k][key]
    );
    return k ? data[k] && data[k][key] : null;
  }

  public getProp(data: OperatorData = {}, key: string) {
    if (this.props[key]) return this.props[key];
    const k = this.references.custom.find(
      (k: string) => data[k] && data[k][key]
    );
    if (k) return this.initProp(initRefs(), data[k][key], key);
    return undefined;
  }

  public getRefs(data: OperatorData = {}): OperatorReferences {
    if (!this.references.custom.length && !this.references.dynamic.length) {
      return this.references;
    }

    const refs: OperatorReferences = JSON.parse(
      JSON.stringify(this.references)
    );
    this.references.custom.forEach((key) => {
      if (data[key]) {
        mapValues(data[key], this.initProp.bind(this, refs));
      }
    });

    refs.dynamic.forEach((key) => {
      const prop = this.getProp(data, key);
      if (prop && prop.referenceFor) {
        const ref = prop.referenceFor(data[key]);
        refs[ref].push(key);
      }
    });
    return refs;
  }

  public getKeys(data: OperatorData = {}): string[] {
    return this.getRefs(data).all;
  }

  public merge(data: OperatorData, attrs: OperatorData) {
    let isChanged = false;
    // console.log('merge', data, attrs);
    for (const key of Object.keys(attrs)) {
      const keyChanged = this.set(data, key, attrs[key]);
      isChanged = isChanged || keyChanged;
    }
    return isChanged;
  }

  public contentExport(data: OperatorData) {
    const refs = this.getRefs(data);
    const result: any = {};
    for (const key of refs.export) {
      result[key] = data[key];
    }
    for (const key of refs.dynamic) {
      const prop = this.getProp(data, key);
      if (prop && prop.referenceFor) {
        const refType = prop.referenceFor(data[key]);
        if (this.exportReferences[refType]) {
          result[key] = data[key];
        }
      }
    }
    // console.log('op export', name, data.name);
    result.type = this.name;

    if (this.useVars && data._vars) {
      result._vars = data._vars;
    }
    return result;
  }

  public get(data: OperatorData, key: string) {
    const prop = this.props[key] || this.getProp(data, key);
    return prop && prop.get ? prop.get(data[key]) : data[key];
  }

  public hasKey(data: OperatorData, key: string) {
    return (
      !!this.props[key] ||
      (key === '_vars' && this.useVars) ||
      !!this.getProp(data, key)
    );
  }
}

export class ActionOperon extends Operon {
  public def: ActionDefinition;
  public update(
    state: ActionState,
    evalAsset: EvaluatedAsset,
    data: OperatorData
  ): ActionState {
    if (this.def.update) {
      return this.def.update(state, evalAsset, data);
    }
    return state;
  }
}

export class OperatorOperon extends Operon {
  public def: OperatorDefinition;

  public update(data: OperatorData, primitive: PlugPrimitive, options?: any) {
    if (this.def.update) return this.def.update(data, primitive, options);
    return primitive;
  }
}

export function makeAttributeOperon(def: OperatorDefinition, name: string) {
  return new Operon(def, name);
}

export function makeOperatorOperon(
  def: OperatorDefinition,
  name: string
): OperatorOperon {
  return new OperatorOperon(def, name);
}
export function makeActionOperon(
  def: ActionDefinition,
  name: string
): ActionOperon {
  return new ActionOperon(def, name);
}

type Hierarchy = {
  [plugName: string]: { [opName: string]: OperatorDefinition };
};

interface MakeAllOperatorsOptions {
  withNone: boolean;
  commonSchema: OperatorSchema;
}

function augmentDefinition(
  op: OperatorDefinition,
  name: string,
  commonSchema: OperatorSchema
): OperatorDefinition {
  for (const propName in commonSchema) {
    const prop = op.schema[propName];
    const commonProp = commonSchema[propName];
    if (prop && prop.type !== commonProp.type) {
      console.warn(
        `Property '${propName}' on operator '${name}' uses a reserved property name, but doesn't match the expected type "${commonProp.type}".`
      );
    }
  }
  return {
    ...op,
    schema: {
      ...commonSchema,
      ...op.schema,
    },
  };
}

export function makeAllOperators(
  hierarchy: Hierarchy,
  { withNone, commonSchema }: MakeAllOperatorsOptions
): {
  [plugName: string]: { [opName: string]: OperatorOperon };
} {
  let records = mapValues(hierarchy, (ops, plug) => {
    return mapValues(ops, (op, name) => {
      const fullOp = augmentDefinition(op, name, commonSchema);
      return makeOperatorOperon(fullOp, name);
    });
  });
  if (withNone) {
    records.None = {
      NoOp: makeOperatorOperon({ schema: commonSchema }, 'NoOp'),
    };
  }
  return records;
}
