import isUuid from 'is-uuid';
import {
  Attribute,
  Action,
  CasState,
  ConfiguratorNode,
  Operon,
  OperatorData,
  Path,
  PropertySchema,
  PropertySchemaInstance,
  Rule,
  SceneGraphNode,
  SchemaType,
} from '../types';
import { lookups } from './lookups';

export const NotFound: unique symbol = Symbol('not-found');

export const allowedChildProps: { [propType in SchemaType]?: string[] } = {
  Vec2: ['x', 'y'],
  Vec3: ['x', 'y', 'z'],
};

export interface ResolvedPath {
  path: Path;
  node?: SceneGraphNode;
  plugName?: string;
  op?: OperatorData;
  propertyName?: string;
  property?: PropertySchemaInstance;

  // children below a property (eg ['rotation','x'])
  childType?: SchemaType;
  childName?: string;
  childContainer?: any;

  configurator?: ConfiguratorNode;
  rule?: Rule;
  action?: Action;
  attribute?: Attribute;

  operon?: Operon; // Operator/Action etc
  value: any | typeof NotFound; // final value at the end of the path, undefined if not reached
  container?: any; //
}

// If we are at the end of the path, assign to result.value and return true.
// otherwise, return false to indicate to keep traversing
function assignValue(
  path: Path,
  result: ResolvedPath,
  v: any,
  idx: number,
  key?: keyof ResolvedPath | null,
  container?: boolean
) {
  if (key) result[key] = v;
  if (container) result.container = v;
  if (path.length - 1 === idx) {
    result.value = v;
    return true;
  }
  return false;
}

// Give an input path, resolve it to the components inside the path.  Each of
// the possible components that may be traversed, are stored in the ResolvedPath
// result.  So `node`, `plugName`, `rules`, `action` etc get filled in if the
// path travels through them.
//
// There are two potential useful end results of a path -- the final component,
// or "value", and the containing component, or "container".  For example, if
// the path is to a string property in an operator, the containing component
// would be the operator data, and the final component would be the string
// property. Thus the containing component can be used to assign a new value,
// and the final component would contain the value at the end of the path.
//
//
export function resolvePath(state: CasState, path: Path): ResolvedPath {
  const result: ResolvedPath = { path, value: NotFound };
  const node = path[0] && state.nodes[path[0]];
  if (!node) return result;
  if (assignValue(path, result, node, 0, 'node')) return result;
  if (path[1] === 'configurator') {
    if (assignValue(path, result, node.configurator, 1, 'configurator')) {
      return result;
    }
    if (path[2] === 'attributes') {
      return resolveAttributePath(state, path.slice(2), result);
    } else if (path[2] === 'rules') {
      return resolveRulePath(state, path.slice(3), result);
    }
  }
  if (path[1] === 'name') {
    if (assignValue(path, result, node.name, 1)) {
      result.propertyName = 'name';
      result.container = node;
      return result;
    }
  }
  const remainder = path[1] === 'plugs' ? path.slice(2) : path.slice(1);
  return resolvePlugPath(state, remainder, result);
}

// Further resolves from the plug onwards. Modifies and returns result
function resolvePlugPath(state: CasState, path: Path, result: ResolvedPath) {
  const node = result.node!; // we know node exists here
  const [plugName, idx, propertyName] = path;
  if (!node.plugs[plugName]) return result;
  result.plugName = plugName;
  const ops = node.plugs[plugName];
  if (assignValue(path, result, ops, 0)) return result;

  let lookupViaProperty = false;
  let op = !isNaN(Number(idx)) ? ops[Number(idx)] : undefined;
  if (!op && isUuid.anyNonNil(idx)) op = ops.find((op) => op.id === idx);
  if (!op && typeof idx === 'string') {
    op = ops.find((op) => op.hasOwnProperty(idx));
    if (op) lookupViaProperty = true;
  }
  if (op) {
    result.op = op;
    // console.log('lookup?', plugName, op.type);
    result.operon = lookups.Operator(plugName, op.type);
    // assign value and container
    if (assignValue(path, result, op, lookupViaProperty ? 0 : 1, null, true))
      return result;
  }
  return resolveOperonPath(
    state,
    path.slice(lookupViaProperty ? 1 : 2),
    result
  );
}

// Further resolves from the rule onwards. Modifies and returns result
function resolveRulePath(state: CasState, path: Path, result: ResolvedPath) {
  const node = result.node!; // we know node exists here
  const ruleIdx = path[0];
  // console.log('resolve rule?', ruleIdx, node.configurator?.rules);
  const rule = node.configurator?.rules[ruleIdx];
  if (!rule) return result;
  if (assignValue(path, result, rule, 0, 'rule')) return result;
  if (path[1] === 'actions') {
    const action = rule.actions[path[2]];
    if (action) {
      result.operon = lookups.Action(undefined, action.type);
      if (assignValue(path, result, action, 2, 'action', true)) return result;

      return resolveOperonPath(state, path.slice(3), result);
    }
  }
  return result;
}

// Given an operon (operator, action etc), resolve the potential property
function resolveOperonPath(state: CasState, path: Path, result: ResolvedPath) {
  const [propertyName, childName] = path;
  if (!result.operon) return result;

  result.propertyName = propertyName;
  if (result.operon.hasKey(result.container, propertyName)) {
    const val = result.container[propertyName];
    if (assignValue(path, result, val, 0)) return result;
    const property = result.operon.getProp(result.container, propertyName);
    const propType = property?.schema.type;
    if (propType && allowedChildProps[propType]?.includes(childName)) {
      const childProp = val[childName];
      if (assignValue(path, result, childProp, 1)) {
        result.childContainer = val;
        result.childName = childName;
        return result;
      }
    }
  }

  // if (result.operon && propertyName) {
  //   result.propertyName = propertyName;
  //   result.property = result.operator.getProp(op, propertyName);
  // }
  return result;
  // const op = obj as OperatorData;
  // const property = operator.getProp(op, p);
  // if (!property) return null;
  // const childIsCorrect =
  //   childProp == null ||
  //   allowedChildProps[property.schema.type]?.includes(childProp);
  // if (!childIsCorrect) return null;
  // const childType = childProp ? 'Number' : undefined;
}

function resolveAttributePath(
  state: CasState,
  path: Path,
  result: ResolvedPath
) {
  const node = result.node!; // we know node exists here
  const id = path[0];
  return result;
}
