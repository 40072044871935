import React, { useContext, useEffect, useState, ReactNode } from 'react';
import {
  AssetState,
  ConfigurationType,
  EvaluatedNode,
  SceneGraphNode,
  Org,
  ThreekitApiOptions,
  ThreekitSource,
} from '../types';
import ThreekitSourceContext from './ThreekitSourceContext';
import ThreekitApiContext from './ThreekitApiContext';
import { evaluateNode } from '../sceneGraph/evaluateNode';
import { lookupComponent } from './nodes';

export interface NodeProps {
  node: SceneGraphNode;
  assetState: AssetState;
  configuration: ConfigurationType;
  childProps?: any;
  attachTo?: string;
}

export interface EvalNodeProps {
  assetState: AssetState;
  configuration: ConfigurationType; // pass this through for configuration propagation
  evalNode: EvaluatedNode;
  children: ReactNode;
  attachTo?: string;
  nodeProps?: any;
  childProps?: any;
  color?: any;
}

const filesCache: Map<SceneGraphNode, EvaluatedNode> = new Map();
const nofilesCache: Map<SceneGraphNode, EvaluatedNode> = new Map();

function cachedEvaluate(
  node: SceneGraphNode,
  source: ThreekitSource,
  opts: ThreekitApiOptions,
  org: Org
) {
  // When we evaluate for api use, we skip downloading files. So we need
  // to ensure that we have a separate cache, so rendering the same node will
  // ensure we have fetched files.
  const cache = opts.skipFiles ? nofilesCache : filesCache;
  let result = cache.get(node);
  if (result) return result;
  const options = { source, ...opts, org };
  // A test that catches promises from here, and evaluates each children in order to 'prefetch'
  // their assets did not improve loading speeds, so it was backed out
  result = evaluateNode(node, options);
  if (result) cache.set(node, result);
  return result;
}

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export default function Node(props: NodeProps) {
  const { configuration, assetState, node, childProps } = props;
  // const [v, setV] = useState<number>(node._v);
  const { source, org } = useContext(ThreekitSourceContext);
  const apiOptions = useContext(ThreekitApiContext);

  const evalNode = cachedEvaluate(node, source, apiOptions, org);

  const Comp = lookupComponent(node.type);

  const nodeProps = childProps && childProps[`${node.name}:${node.type}`];

  if (nodeProps) {
    // console.log( 'NodeProps: ', node.name, node.type, nodeProps, 'from', childProps);
  }

  // @ts-ignore
  const visible = evalNode.Properties ? evalNode.Properties.visible : null;
  if (visible === false) {
    return null;
  }

  return (
    <Comp {...props} nodeProps={nodeProps} evalNode={evalNode}>
      {node.children.map((id: string) => {
        return (
          <Node
            node={assetState.nodes[id]}
            assetState={assetState}
            configuration={configuration}
            childProps={childProps}
            key={id}
          />
        );
      })}
    </Comp>
  );
}
