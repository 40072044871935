import React, { ReactNode } from 'react';
import ThreekitSourceContext, {
  apiRoot,
  defaultThreekitEnv,
  createSource,
} from './ThreekitSourceContext';
import axios from 'axios';
import { suspend } from 'suspend-react';
import { fetchOrgFromToken, fetchOrg } from '../queries/users';

interface ThreekitSourceProps {
  token: string;
  env?: string;
  org?: string; // slug or uuid
}

type PropsWithChildren<P> = P & { children?: ReactNode };

export default function ThreekitSource(
  props: PropsWithChildren<ThreekitSourceProps>
) {
  const { token } = props;

  if (!token) {
    console.error('token expected');
    return null;
  }
  const env = props.env || defaultThreekitEnv;

  const source = createSource(env, token);

  const org = suspend(async () => {
    const result = props.org
      ? await fetchOrg(source, props.org)
      : await fetchOrgFromToken(source, token);
    // console.log('Org', result);
    return result;
  }, [env, token]);

  // instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  return (
    <ThreekitSourceContext.Provider value={{ source, org }}>
      {props.children}
    </ThreekitSourceContext.Provider>
  );
}
