import {
  ActionDefinition,
  ActionState,
  Attribute,
  AttributeStateValue,
  EvaluatedAsset,
} from '../types';
import { keys, pickBy } from 'lodash';

const setAttributeValueVisibility: ActionDefinition = {
  supports: ['item', 'material', 'model', 'scene', 'texture'],
  label: 'Set Attribute Value Visibility',
  schema: {
    attributeId: {
      type: 'Attribute',
      label: 'Attribute',
      filterBy: 'values',
    },
    values: { type: 'AttributeValues', label: 'Values', from: 'attributeId' }, // values: { type: 'AttributeValues', label: 'Values', from 'attributeId' }
    enabled: { type: 'Boolean', label: 'Enabled' },
  },
  update: (
    input: ActionState,
    evalAsset: EvaluatedAsset,
    args: { attributeId: string; values: any; enabled: Boolean }
  ) => {
    const { attributeStateMap, assetState } = input;
    const { attributeId, enabled } = args;
    if (!attributeId || !args.values || !args.values.length) return input;
    const attrState = input.attributeStateMap[attributeId];
    if (!attrState) {
      throw new Error('Unknown attribute state for ' + attributeId);
    }
    const attr = assetState.nodes[assetState.id].configurator?.attributes.find(
      (attr) => attr.id === attributeId
    );
    if (!attr) {
      throw new Error('Invalid attribute' + attributeId);
    }

    if (!attrState.values) {
      // The values may have not been resolved, if we didn't ask for a configurator
      // skip this action in this case, since it is UI sugar
      return input;
    }

    const values = processValues(
      'visible',
      enabled,
      attr,
      [...attrState.values],
      args.values
    );
    // Create new hiddenValues array from values state
    const hiddenValues = values.filter((v) => !v.visible).map((v) => v.value);

    // console.warn(args, attrState, attr, result);
    return {
      ...input,
      attributeStateMap: {
        ...input.attributeStateMap,
        ...{ [attributeId]: { ...attrState, values, hiddenValues } },
      },
    };
  },
};

// Update the `values` Attribute state array to be visible/enabled as corresponding
// to the input `modifyValues` array.
export function processValues(
  key: string,
  enabled: Boolean,
  attr: Attribute,
  values: AttributeStateValue[],
  modifyValues: string[]
) {
  // console.log('processValues', key, enabled, attr, values, modifyValues);
  const updateIndex = (idx: number) => {
    if (idx !== -1) values[idx] = { ...values[idx], [key]: enabled };
  };

  modifyValues.forEach((val: string) => {
    if (val === '*') {
      values.forEach((v, idx) => updateIndex(idx));
    } else if (val[0] === '#' && attr.type === 'Asset') {
      // pickBy on an array returns object with (string) index as keys
      const matches = pickBy(values, (v) => {
        return v.tags && v.tags.includes(val.slice(1));
      });
      keys(matches).forEach((n: string) => updateIndex(Number(n)));
    } else {
      updateIndex(
        values.findIndex((stateValue) => {
          return attr.type === 'Asset'
            ? stateValue.assetId === val
            : stateValue.value === val;
        })
      );
    }
  });
  return values;
}

export default setAttributeValueVisibility;
