import {
  ActionState,
  AssetState,
  AttributeStateMap,
  ConfigurationType,
  DisplayAttribute,
  EvaluatedAsset,
  OnAttributes,
  SceneGraphNode,
  ThreekitSource,
} from '../types';
import applyRules from './applyRules';
import setConfiguration from './setConfiguration';
import getDisplayAttributes from './getDisplayAttributes';

const evalCache: Map<SceneGraphNode, EvaluatedAsset | false> = new Map();
const configCache: Map<
  EvaluatedAsset,
  Map<ConfigurationType, ActionState>
> = new Map();

const emptyConfig = {}; // constant for no config given

export default function applyConfiguration(
  initialState: AssetState,
  evaluatedAsset: EvaluatedAsset | false,
  inputAttributeStateMap: AttributeStateMap,
  inputConfiguration?: ConfigurationType,
  onAttributes?: OnAttributes
): ActionState {
  if (!evaluatedAsset)
    return {
      assetState: initialState,
      configuration: {},
      attributeStateMap: {},
    };

  const inputConf = inputConfiguration || emptyConfig;

  let configMap = configCache.get(evaluatedAsset);
  if (!configMap) {
    configMap = new Map();
    configCache.set(evaluatedAsset, configMap);
  }
  const result = configMap.get(inputConf);
  if (result) return result; // input configuration has been evaluated

  // Take input conf, validate it through the attribute schema, and union it with
  // the default configuration, to get a full configuration
  const conf = setConfiguration(evaluatedAsset, inputConf);

  // Given the input state, evaluated asset and configuration, apply the rules.
  // This can modify any of the assetState, configuration (eg set-attribute-value), or
  // attributesState (eg set-attribute-visible);
  const actionState = applyRules(
    initialState,
    evaluatedAsset,
    inputAttributeStateMap,
    conf
  );
  configMap.set(inputConf, actionState);

  // Put the callback hook here, so it only happens on changes
  if (onAttributes) {
    onAttributes(getDisplayAttributes(evaluatedAsset.attributes, actionState));
  }
  return actionState;
}
