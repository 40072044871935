import { ActionDefinition, ActionState, EvaluatedAsset } from '../types';

const setActiveCamera: ActionDefinition = {
  supports: ['model', 'scene'],
  label: 'Set Active Camera',
  schema: {
    cameraId: {
      type: 'Node',
      nodeType: 'Camera',
    },
  },

  update: (
    input: ActionState,
    evalAsset: EvaluatedAsset,
    { cameraId }: any
  ) => {
    // console.warn('TODO: setActiveCamera', cameraId);
    return input;
  },
};

export default setActiveCamera;
