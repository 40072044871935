import { ActionDefinition, ActionState, EvaluatedAsset } from '../types';
import resolveNodeTags from './resolveNodeTags';
import { set } from '../cas/module';

const setModel: ActionDefinition = {
  supports: ['material', 'model', 'scene', 'texture'],
  label: 'Set Model',
  schema: {
    model: { type: 'Node', label: 'Model', nodeType: ['Model'] },
    metadataQuery: { type: 'Object', label: 'Metadata Query' },
    value: { type: 'Asset', assetType: 'Model', label: 'Asset' },
    instance: { type: 'Boolean', label: 'Instance' },
  },
  update: (
    input: ActionState,
    evalAsset: EvaluatedAsset,
    args: { model: string; value: any; instance: boolean }
  ) => {
    const { model, value, instance } = args;
    if (!value || !model) return input;

    return {
      ...input,
      assetState: set(input.assetState, [model, 'Null', 'asset'], value),
    };
  },
};

export default setModel;
