import { useLoader, ThreeEvent } from '@react-three/fiber';
import { omit } from 'lodash';
import React, {
  ReactNode,
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import AssetLoader from './AssetLoader';
import ThreekitSourceContext from './ThreekitSourceContext';
import ThreekitApiContext from './ThreekitApiContext';
import Node from './Node';
import {
  DisplayAttribute,
  AssetState,
  AttributeStateMap,
  ConfigurationType,
  EvaluatedAsset,
  OnAttributes,
  SceneGraphNode,
  ThreekitSource,
} from '../types';
import applyRules from '../sceneGraph/applyRules';
import evaluateAsset from '../sceneGraph/evaluateAsset';
import applyConfiguration from '../sceneGraph/applyConfiguration';
import { AssetFindOptions } from '../queries/assets';

interface EvaluatedCache {
  evaluatedAsset: EvaluatedAsset | false;
  attributeStateMap: AttributeStateMap;
}
const evalCache: Map<SceneGraphNode, EvaluatedCache> = new Map();

// Evaluate the asset if required, and return EvaluatedAsset.
//
// This should only evaluate when the `configurator` changes, rather than the
// node itself. This might have an effect for Materials, and Images, where the
// root node is also the node with data, unlike say Models, where we have a
// container null at the root.
function cachedEvaluateAsset(
  state: AssetState,
  source: ThreekitSource,
  resolveAttributes: boolean
) {
  const node = state.nodes[state.id];
  let result = evalCache.get(node);
  // console.log('cached', node.name, result && result.node === node);
  if (result !== undefined) return result;
  result = evaluateAsset(node, source, resolveAttributes);
  evalCache.set(node, result);
  return result;
}

interface AssetProps {
  id: string | AssetFindOptions; // required one of `id` or `assetState`
  configuration?: ConfigurationType;
  onAttributes?: OnAttributes;
  assetState?: AssetState;
  onApi?: Function;
  overrides?: any;
  fromId?: string; // This is an internal prop, used to distinguish between an internal, nested asset and an externally requested asset
  withStage?: Boolean | string;
}

function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  }, [value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
}

export default function Asset(props: AssetProps) {
  const { onAttributes, withStage } = props;
  const { source } = useContext(ThreekitSourceContext);
  const threekitApi = useContext(ThreekitApiContext);

  if (!props.id && !props.assetState) {
    throw new Error('One of `id` or `assetState` must be provided');
  }
  if (props.id && props.assetState && props.id === props.assetState.id) {
    throw new Error('Only one of `id` or `assetState` must be provided');
  }

  // We can override the initial assetState if provided
  let inputAssetState =
    props.assetState && props.assetState.id === props.id
      ? props.assetState
      : useLoader<AssetState, any>(
          AssetLoader,
          props.id,
          AssetLoader.attach(source) //, !props.fromId && !!props.useManifest) // If no `fromId` provided, fetch with manifest
        );

  // `useLoader` incorrectly types the return as possibly being an array. hack fix for type error
  if (Array.isArray(inputAssetState)) inputAssetState = inputAssetState[0];

  const id = inputAssetState.id; // resolved id

  const { evaluatedAsset, attributeStateMap } = cachedEvaluateAsset(
    inputAssetState,
    source,
    !!onAttributes // resolve attribute values
  );

  const inputConf = props.configuration || {};
  // const prevConfiguration = usePrevious(inputConf); // use this hook for 'changed' conditions

  // given input state, apply new configuration (cached by config)
  const { configuration, assetState } = applyConfiguration(
    inputAssetState,
    evaluatedAsset,
    attributeStateMap,
    inputConf,
    onAttributes
  );

  // Add our asset to the registry.
  useEffect(() => {
    const parentId = props.assetState?.id;
    threekitApi.addAsset(
      { source, assetState: assetState, configuration },
      parentId === id ? undefined : parentId
    );
  }, [id]);

  // Check if we want ot render a stage
  const stageId =
    withStage &&
    (typeof withStage === 'string'
      ? withStage
      : assetState.asset.defaultStageId);

  if (stageId) {
    // console.log('render stage with node', stageId);
    return (
      <Asset
        id={stageId}
        key={stageId}
        configuration={{ Asset: { assetId: id, configuration } }}
      />
    );
  }

  return (
    <Node
      {...omit(props, 'withStage')}
      node={assetState.nodes[id]}
      assetState={assetState}
      configuration={configuration}
    />
  );
}
