import { ActionDefinition, ActionState, EvaluatedAsset } from '../types';

const setAttributeEnabled: ActionDefinition = {
  supports: ['item', 'material', 'model', 'scene', 'texture'],
  label: 'Set Attribute Enabled',
  schema: {
    attributeId: { type: 'Attribute', label: 'Attribute' },
    enabled: { type: 'Boolean', label: 'Enabled' },
  },
  update: (
    input: ActionState,
    evalAsset: EvaluatedAsset,
    // attributeStates: AttributeStateMap,
    { attributeId, enabled }: any
  ) => {
    if (!attributeId) return input;

    const inputState = input.attributeStateMap[attributeId];
    if (!inputState) throw new Error('Invalid Attribute State');

    return {
      ...input,
      attributeStateMap: {
        ...input.attributeStateMap,
        [attributeId]: { ...inputState, enabled },
      },
    };
  },
};

export default setAttributeEnabled;
