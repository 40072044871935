import {
  ActionState,
  AssetState,
  Attribute,
  AttributeStateMap,
  ConfigurationType,
  EvaluatedAsset,
  EvaluatedRule,
} from '../types';
import findAttribute from './findAttribute';
import checkConditions from './checkConditions';

export default function applyRules(
  inputState: AssetState,
  evalAsset: EvaluatedAsset,
  attributeStateMap: AttributeStateMap,
  inputConf: ConfigurationType
): ActionState {
  const { attributes, rules } = evalAsset;

  const output = rules.reduce(
    (ruleState: ActionState, resolvedRule: EvaluatedRule) => {
      const conf = ruleState.configuration;
      const { rule, actions } = resolvedRule;
      if (rule.disabled) return ruleState;

      if (rule.conditions && rule.conditions.length) {
        const match = checkConditions(
          ruleState.assetState,
          evalAsset,
          rule.conditions,
          conf
        );
        // console.log(
        //   'check conditions',
        //   rule.conditions,
        //   evalAsset.condOperon,
        //   match
        // );
        if (!match) return ruleState;
      }

      return actions.reduce((currState: ActionState, { operon, action }) => {
        if (!operon) {
          console.warn(`Unknown action`, action);
          return currState;
        }
        const vals = action._vars
          ? configurationFor(attributes, action._vars, conf)
          : {};

        // console.log('run action with', vals, action);
        const outputState = operon.update(currState, evalAsset, {
          ...action,
          ...vals,
        });

        return outputState;
      }, ruleState);
    },
    { assetState: inputState, configuration: inputConf, attributeStateMap }
  );

  return output;
}

function configurationFor(
  attributes: Attribute[],
  attrMap: any,
  configuration: ConfigurationType
) {
  // console.log('configuration for?', attrMap);
  const result: ConfigurationType = {};
  Object.keys(attrMap).forEach((key: string) => {
    const id = attrMap[key];
    const { attr, attrIndex } = findAttribute(attributes, id);
    if (attr) {
      const attrVal = configuration[attr.name];
      // If we're out of bounds, it's expected that the value be "undefined"
      result[key] = attrIndex != null ? attrVal[attrIndex] : attrVal;
    }
  });
  return result;
}
