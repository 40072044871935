import produce from 'immer';
import { resolvePath, NotFound } from './resolvePath';
import { CasState, Path, ThreekitSource } from '../types';
import setDiff from './setDiff';

// where does produce belong. here, or external?
export function set(state: CasState, path: Path, value: any) {
  return produce(state, (draftState) => {
    return setDiff(draftState, { path, value }); // don't return
  });
}

export function get(state: CasState, path: Path) {
  const result = resolvePath(state, path);
  return result.value;
}
