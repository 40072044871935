import produce from 'immer';
import lodash from 'lodash';
import { CasState, Path } from '../types';
import { DiffObject, diffTree } from './diffTree';
import addDiff from './addDiff';
import setDiff from './setDiff';

function _getCommit(cas: CasState, commitHash: string) {
  if (!cas.commits[commitHash]) {
    if (!cas.objects[commitHash]) {
      // TODO: make this throw an error
      return null;
    }
    const commitObj = JSON.parse(cas.objects[commitHash]);
    if (!commitObj.root) {
      // invalid commit
      return null;
    }
    commitObj.createdAt = new Date(commitObj.createdAt);
    // cas.commits[commitHash] = commitObj;
    return commitObj;
  }
  // with immutable state, this local cache needs rework
  return cas.commits[commitHash];
}

/*
 * This action creator is responsible for setting the state to match the
 * commits given. There are a few scenarios:
 * 1. toHead, no fromHead -- initial checkout, just set to 'to' commit
 * 2. toHead and fromHead -- move from 'from' commit to 'to' commit
 * 3. toHead, fromHead and secondINDEX-- this is a three way merge, where we start with the shared
 *     commit 'from', add 'to' on top, then add 'second' on top of that.
 */
export default function setToCommit(
  id: string,
  state: CasState,
  payload: {
    INDEX?: string;
    toHEAD?: string;
    secondINDEX?: string;
    fromHEAD?: string;
    UNDO?: string;
    REDO?: string[];
  }
) {
  const { toHEAD, secondINDEX, fromHEAD, UNDO, REDO } = payload;
  // const { diffActions, type, orgId } = inputs;

  const { objects, refs } = state;

  const toCommit = toHEAD && _getCommit(state, toHEAD);
  // console.log('setTo', toHEAD, fromHEAD, 'root', toCommit.root);
  const INDEX = payload.INDEX || (toCommit && toCommit.root);
  const type = toCommit.type ?? 'Node';

  const fromCommit = fromHEAD
    ? _getCommit(state, fromHEAD)
    : refs.INDEX
    ? { id, root: refs.INDEX }
    : null;

  if (fromCommit && fromCommit.id !== id) {
    throw new Error('Checkout commit with different id than from');
  }

  // console.log('setToCommit', toHEAD, toCommit, fromCommit, secondCommit);

  const diffs = diffTree(
    id,
    type,
    objects,
    INDEX,
    fromCommit && fromCommit.root,
    secondINDEX
  );
  const [removes, addsets] = lodash.partition(
    diffs,
    (diff: any) => diff.action === 'REMOVE'
  );

  const nextState = addsets.reduce((currState: CasState, diff: DiffObject) => {
    const { action, path, value, type } = diff;
    return produce(currState, (draftState) => {
      if (action === 'ADD') {
        addDiff(draftState, { value, path, type });
      } else if (action === 'SET') {
        setDiff(draftState, { value, path });
      }
    });
  }, state);

  // do removes first, since a reparent is a delete/add, and if we
  // do the delete last, the node will disappear
  //   removes.map(handleDiff);
  //   addsets.map(handleDiff);
  // });
  // console.log('setToCommit::next', nextState);
  return nextState;
}
