import { useLoader } from '@react-three/fiber';
import produce from 'immer';
import * as THREE from 'three';
import fetchContent from '../cas/fetchContent';
import { AssetFindOptions, get, find } from '../queries/assets';
import { getManifest } from '../queries/cas';
import { imageUrl } from '../queries/files';
import {
  AssetModel,
  AssetState,
  Attribute,
  CasState,
  SceneGraphNode,
  ThreekitSource,
} from '../types';
import BingeomLoader from '../operators/PolyMesh/BingeomLoader';

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
// export default function assetLoader(httpClient: typeof axios) {

async function resolve(source: ThreekitSource, id: string | AssetFindOptions) {
  if (typeof id === 'string') return id;
  const { assets } = await find(source, id);
  return assets.length ? assets[0].id : undefined;
}

async function loadManifest(source: ThreekitSource, id: string) {
  const manifest = await getManifest(source, id);
  // console.log('useManifest', id, manifest);
  manifest.prefetch.forEach((info: any) => {
    try {
      if (info.type === 'asset' && info.assetType === 'Node') {
        if (info.id !== id) {
          useLoader(AssetLoader, info.id, AssetLoader.attach(source, false));
        }
      } else if (info.type === 'mesh') {
        useLoader(BingeomLoader, info.file, BingeomLoader.attach(source));
      } else if (info.type === 'image') {
        const url = imageUrl(source, info.file.hash);
        useLoader(THREE.TextureLoader, url);
        // console.log('prefetch image?', info);
      }
    } catch (err) {} // ignore errors from prefetch
  });
}

async function resolveGlobalAttributes(
  assetState: AssetState,
  source: ThreekitSource
) {
  const node = assetState.nodes[assetState.id];
  const globalAttrs =
    node.configurator?.attributes.filter((attr) => attr.type === 'Global') ??
    [];
  const states = await Promise.all(
    globalAttrs.map((attr) => fetchContent(source, attr.id))
  );
  return produce(assetState, (draftState) => {
    states.forEach((attrState) => {
      const attr = draftState.nodes[
        assetState.id
      ].configurator?.attributes.find((attr) => attr.id === attrState.id);
      if (attr) Object.assign(attr, attrState.attributes[attr.id]);
      else throw new Error('Error with global attribute');
      // Object.assign(draftState.attributes, attrState.attributes);
    });
  });
}

export default class AssetLoader extends THREE.Loader {
  source: ThreekitSource; // this must be set to use this loader
  useManifest: Boolean = false;

  static attach(source: ThreekitSource, useManifest?: Boolean) {
    return (l: AssetLoader) => {
      l.source = source;
      l.useManifest = useManifest ?? false;
    };
  }

  public load(
    idOrQuery: string | AssetFindOptions,
    onLoad?: (result: AssetState) => void,
    onProgress?: Function,
    onError?: Function
  ) {
    const source = this.source;
    const useManifest = this.useManifest;
    if (!source) {
      throw new Error('ThreekitSource not found for AssetLoader');
    }

    function err(str: string) {
      if (onError) onError(str);
      else console.error(str);
    }

    async function doLoad() {
      const id = await resolve(source, idOrQuery);
      if (!id) {
        return err('Invalid Asset');
      }

      if (useManifest) {
        await loadManifest(source, id);
      }

      const [asset, casState]: [AssetModel | undefined, CasState] =
        await Promise.all([get(source, id), fetchContent(source, id)]);
      if (!asset) {
        return err('No Asset');
      }
      const assetState = { ...casState, asset, attributes: {} };

      let result: AssetState = produce(assetState, () => {});
      // const asset = await ;
      // let result: AssetState = await ;
      result = await resolveGlobalAttributes(result, source);
      // result = await resolveAssetValues(result, source);
      // console.log('Asset loader delay', id, result);
      // if (onLoad) sleep(1000).then(() => onLoad(result));
      if (onLoad) onLoad(result);
      else err('No onLoad callback');
    }
    doLoad();
  }
}
// }
