import React from 'react';
import { useFrame, useLoader } from '@react-three/fiber';
import { EvalNodeProps } from '../Node';
import { EvaluatedImageNode } from '../../types';
import { LinearEncoding, sRGBEncoding } from 'three';

interface ImageEvalNodeProps extends EvalNodeProps {
  evalNode: EvaluatedImageNode;
  overrides?: { uvTransform: any };
  from?: any;
}

export default function Image(props: ImageEvalNodeProps) {
  // console.log('Image props?', props);
  const { children, evalNode, nodeProps } = props;
  const { node } = evalNode;

  const texture = evalNode.Image.texture;
  if (!texture) return null;

  const uvTransform = props.overrides?.uvTransform;

  // if (!!uvTransform) {
  //   console.log('Image override', uvTransform, 'texture', texture, props.attachTo);
  // }

  switch (props.attachTo) {
    case 'map':
    case 'lightMap':
    case 'emissiveMap':
      // console.log("setting encoding to emissive for map: ", props.attachTo)
      texture.encoding = sRGBEncoding;
      break;
  }

  function addMap(child: any, mat: any) {
    if (props.attachTo) {
      // console.log('addMap', props.attachTo, props.from, uvTransform, node.id, mat, texture);
      let t = texture; //.clone() shouldn't need to clone, since these are unique instances?
      if (uvTransform) {
        t.offset = uvTransform.uvOffset;
        t.rotation = uvTransform.rotation;
        t.repeat = uvTransform.uvTile;
        // if (props.attachTo === 'map') {
        //   t.repeat = new Vector2(5, 5);
        // }
      }
      mat[props.attachTo] = t;
      mat.needsUpdate = true;
    }
  }
  function removeMap(child: any, mat: any) {
    // console.log('removeMap', props.attachTo, props.from, mat, child, node);
    if (props.attachTo) {
      mat[props.attachTo] = undefined;
      mat.needsUpdate = true;
    }
  }

  // Image change is not reflected, so call this onUpdate?
  function onUpdate(child: any) {
    const mat = child.__r3f.parent;
    if (!mat) return;
    if (props.attachTo && mat[props.attachTo] !== texture) {
      // console.log('update map', texture, mat[props.attachTo]);
      addMap(child, mat);
    }
    // }
  }

  return (
    <group
      key={node.id}
      name={'Image:' + node.name}
      attachFns={[addMap, removeMap]}
      onUpdate={onUpdate}
    >
      {children}
    </group>
  );
}
