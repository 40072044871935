import { mapValues } from 'lodash';
import React, { ReactNode } from 'react';
import ThreekitApiProvider from './components/ThreekitApiProvider';
import { create } from './renderer';
import {
  AssetState,
  ConfigurationType,
  ThreekitApi,
  ThreekitApiData,
  ThreekitHeadlessApi,
  ThreekitSource,
} from './types';
export type { AssetState };
import { get, set } from './cas/module';
import queries from './queries';
const { assets, cas, files } = queries;

export async function initHeadlessAsset(
  element: ReactNode
): Promise<ThreekitHeadlessApi & ThreekitApiData> {
  let apiData: ThreekitApiData;

  function addAsset(data: ThreekitApiData, parentId?: string) {
    if (!parentId && !apiData) {
      apiData = data;
    }
    // console.log('renderer, addAsset', apiId, id, parentId);
  }
  function Wrapped(props: { children?: ReactNode }) {
    return (
      <ThreekitApiProvider addAsset={addAsset} skipFiles={true}>
        <React.Suspense fallback={null}>{props.children}</React.Suspense>
      </ThreekitApiProvider>
    );
    // return <React.Suspense fallback={null}>{element}</React.Suspense>;
  }

  const renderer = await create(<Wrapped>{element}</Wrapped>);
  await renderer.waitUntilRendered();
  if (!apiData!) throw new Error('apidata undefined');

  return {
    ...apiData!,

    evaluateAsset: async () => {
      await renderer.update(<Wrapped>{element}</Wrapped>);
    },
    unmount: async () => {
      return renderer.unmount();
    },

    toGraph: renderer.toGraph,
  };
}

export const api: ThreekitApi = {
  scene: { get, set },
  queriesFor: (source: ThreekitSource) => {
    return {
      assets: {
        get: assets.get.bind(this, source),
        find: assets.find.bind(this, source),
      },
    };
  },
};
