import { Vector3, Vector4 } from 'three';

import ObjectArrayView from '../../generic/container/ObjectArrayView';
import ObjectsByName from '../../generic/container/ObjectsByName';
import PolyMap from '../model/PolyMap';
import PolyMaps from '../model/PolyMaps';
import PolyMesh from '../model/PolyMesh';

import flipMesh from './flipMesh';

function mergeOffsets(offsets0: Uint32Array, offsets1: Uint32Array) {
  const offsets1Delta = offsets0[offsets0.length - 1];

  const faceRangeOffsets = new Uint32Array(
    offsets0.length + offsets1.length - 1
  );
  const offsets1Offset = offsets0.length;

  faceRangeOffsets.set(offsets0, 0);
  for (let src = 1, dst = offsets1Offset; src < offsets1.length; ++src, ++dst) {
    faceRangeOffsets[dst] = offsets1[src] + offsets1Delta;
  }

  return faceRangeOffsets;
}

function mergeMap(
  map0: PolyMap<any>,
  map1: PolyMap<any>,
  faceRangeOffsets: Uint32Array
): PolyMap<any> {
  const { faceValueIndices: indices0, values: values0 } = map0;
  const { faceValueIndices: indices1, values: values1 } = map1;

  let values: ObjectArrayView;
  let values1Offset: number;
  if (values0 === values1) {
    values = values0;
    values1Offset = 0;
  } else {
    const data = new Float32Array(values0.data.length + values1.data.length);
    data.set(values0.data, 0);
    data.set(values1.data, values0.data.length);

    values = new ObjectArrayView(
      values0.type,
      values0.length + values1.length,
      data
    );
    values1Offset = values0.length;
  }

  const faceValueIndices: Uint32Array = new Uint32Array(
    indices0.length + indices1.length
  );
  const indices1Offset = indices0.length;

  faceValueIndices.set(indices0, 0);
  for (let src = 0, dst = indices1Offset; src < indices1.length; ++src, ++dst) {
    faceValueIndices[dst] = indices1[src] + values1Offset;
  }

  return new PolyMap({
    values,
    faceValueIndices,
    faceRangeOffsets,
  });
}

export default function (mesh: PolyMesh) {
  const origOffsets = mesh.faceRangeOffsets;
  const flipedMesh = flipMesh(mesh, { flipNormals: true, flipTangents: true });
  const flippedOffsets = flipedMesh.faceRangeOffsets;
  const faceRangeOffsets = mergeOffsets(origOffsets, flippedOffsets);
  const newPolyMesh = {
    colorMaps: new ObjectsByName(),
    uvMaps: new ObjectsByName(),
    faceRangeOffsets,
  };

  // @ts-ignore
  const polyMapsIterator = new PolyMaps.Iterator(mesh);
  while (polyMapsIterator.next()) {
    const mapId = polyMapsIterator.getId();

    // @ts-ignore
    const srcMap = PolyMaps.resolveMap(mesh, mapId);
    const flipMap = PolyMaps.resolveMap(flipedMesh, mapId);

    const mergedMap = mergeMap(srcMap, flipMap, faceRangeOffsets);

    // @ts-ignore
    PolyMaps.assignMap(newPolyMesh, mapId, mergedMap);
  }

  return new PolyMesh(newPolyMesh);
}
