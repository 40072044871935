import isUuid from 'is-uuid';
import lodash from 'lodash';
import {
  ActionDefinition,
  ActionOperon,
  AssetState,
  SceneGraphNode,
  SupportedType,
} from '../types';
import customScript from './customScript';
import setActiveCamera from './setActiveCamera';
import setAttributeVisible from './setAttributeVisible';
import setAttributeEnabled from './setAttributeEnabled';
import setProperty from './setProperty';
import setMaterial from './setMaterial';
import setModel from './setModel';
import setAttributeValue from './setAttributeValue';
import setVisibility from './setVisibility';
import setAttributeValueEnabled from './setAttributeValueEnabled';
import setAttributeValueVisibility from './setAttributeValueVisibility';

import { makeActionOperon } from '../cas/Operon';

export type ActionType = 'set-property' | 'set-visibility';

export interface Action {
  label: string;
  schema: any;
  execute: Function;
}

export interface tableQueryParams {
  column: string;
  comparator: string;
  attributeId?: string;
  value?: string;
}

interface DatatableQuery {
  tableId: string;
  conditions: tableQueryParams[];
  column: string;
}

interface MetadataCondition {
  key: string;
  comparator: string;
  attributeId?: string;
  value?: string;
}

interface MetadataQuery {
  type?: string;
  assetType?: string;
  property?: string;
  conditions: MetadataCondition[];
}

export interface MeataQueryCache {
  assetType: string;
  metadataQuery: string;
  orgId: string;
}

/*
const createAttributeValuePropertyUpdate = (
  prop: string,
  to: 'hiddenValues' | 'disabledValues'
) => async (
  store: ThreekitStore,
  configurator: Configurator,
  properties: { [key: string]: any }
) => {
  const { attributeId, values } = properties;
  if (!attributeId || !values || !values.length) {
    return;
  }
  const { attrId, attrIndex } = readAttributeId(attributeId);
  const attributeState = configurator.getAttributeState();

  const attribute = attributeState[attrId!];
  if (!attribute) {
    return;
  }
  const result = attribute.values;
  const property: string[] = [];

  values.forEach((val: string | string[] | { assetId: string }) => {
    if (val === '*') {
      result.map((item: { [key: string]: any }) => {
        item[prop] = properties[prop];
        property.push(item.assetId || item.value);
      });
    } else if (Array.isArray(val)) {
      // logic of tag group
      const tags = val.slice(1).map((tag) => tag.slice(1));
      result.map((item: { [key: string]: any }) => {
        for (const tag of tags) {
          if (!item.tags.includes(tag)) return;
        }
        item[prop] = properties[prop];
        property.push(item.assetId || item.value);
      });
    } else if (typeof val === 'string') {
      if (val[0] === '#') {
        const tag = val.slice(1);
        result.map((item: { [key: string]: any }) => {
          if (item.tags.includes(tag)) {
            item[prop] = properties[prop];
            property.push(item.assetId || item.value);
          }
        });
      } else {
        const exist = result.find(
          (item: { value?: string; assetId: string }) =>
            item?.value === val || item?.assetId === val
        );
        if (exist) {
          exist[prop] = properties[prop];
          property.push(exist.assetId || exist.value);
        }
      }
    } else {
      const exist = result.find(
        (item: { assetId: string }) => item?.assetId === val?.assetId
      );
      if (exist) {
        exist[prop] = properties[prop];
        property.push(exist.assetId || exist.value);
      }
    }
  });
  configurator.setAttributeState(attributeId, {
    values: result,
    [to]: lodash.uniq(property),
  });
};
*/

const actionSchemas: { [key: string]: ActionDefinition } = {
  'custom-script': customScript,
  'set-active-camera': setActiveCamera,
  'set-attribute-visible': setAttributeVisible,
  'set-attribute-enabled': setAttributeEnabled,
  'set-property': setProperty,
  'set-visibility': setVisibility,
  'set-material': setMaterial,
  'set-model': setModel,
  'set-attribute-value': setAttributeValue,
  'set-attribute-value-enabled': setAttributeValueEnabled,
  'set-attribute-value-visibility': setAttributeValueVisibility,
};

// addActions(actionMap);

const makeActionOperonWithUseVars = (op: ActionDefinition, key: string) => {
  return makeActionOperon({ ...op, useVars: true }, key);
};

export const actionOperons: {
  [type: string]: ActionOperon;
} = lodash.mapValues(actionSchemas, makeActionOperonWithUseVars);

// export const actionOperons = { ...casActionOperators };

interface Options {
  casOnly?: boolean;
  type?: SupportedType;
}

// export const getOptions = ({
//   casOnly,
//   type,
// }: Options): Array<{ label: string; value: string }> => {
//   let operators = casOnly ? casActionOperators : actionOperators;

//   if (!!type) {
//     operators = Object.entries(operators).reduce<{ [type: string]: Operator }>(
//       (acc, [key, op]) => {
//         const { supports = [] } = op.def;
//         if (supports.includes(type)) {
//           acc[key] = op;
//         }
//         return acc;
//       },
//       {}
//     );
//   }

//   return Object.keys(operators).map((key) => ({
//     label: key.replace(/-/g, ' '),
//     value: key,
//   }));
// };
