import { ActionDefinition, ActionState, EvaluatedAsset } from '../types';

const customScript: ActionDefinition = {
  supports: ['item', 'material', 'model', 'scene', 'texture'],
  label: 'Custom Script',
  schema: {
    content: { type: 'String' },
    enabled: { type: 'Boolean' },
    error: { type: 'String' },
  },
  update: (
    input: ActionState,
    evalAsset: EvaluatedAsset,
    { content, enabled }: any
  ) => {
    console.warn('TODO Action custom-script', content && content.length);
    return input;
  },
};
export default customScript;
