import * as React from 'react';

import {
  _roots as mockRoots,
  render,
  reconciler,
  unmountComponentAtNode as unmount,
  act as _act,
} from '@react-three/fiber';

import { toTree } from './helpers/tree';
import { toGraph } from './helpers/graph';
import { is } from './helpers/is';

import { createCanvas } from './createTestCanvas';
import { createWebGLContext } from './createWebGLContext';
import { createEventFirer } from './fireEvent';

import type { CreateOptions, Renderer, Act, WaitOptions } from './types/public';
import { wrapFiber } from './createTestInstance';

import { asyncUtils } from './asyncUtils';

interface FiberNode {
  tag: number;
  key: string | null;
  type: any;
  elementType: any;
  return?: FiberNode;
  child?: FiberNode;
  sibling?: FiberNode;
}

function fiberToString(fn: FiberNode) {
  const et = fn.elementType;
  let ets = !et
    ? '<null>'
    : typeof et === 'function'
    ? et.name
    : typeof et === 'object' && et['$$typeof']
    ? et['$$typeof'].toString()
    : et.toString
    ? et.toString()
    : '?';

  // if (fn.tag === 10) ets = `${et}`
  return `${fn.tag} ${fn.key} ${ets}`;
}

function outputFiberTree(fn: FiberNode, depth: number): string {
  // if (fn.tag === 10) ets = `${et}`
  let result = `${new Array(depth).join('  ')}${fiberToString(fn)}\n`;
  // console.log(fn.tag, fn.key, fn.elementType)
  if (fn.child) result += outputFiberTree(fn.child, depth + 1);
  if (fn.sibling) result += outputFiberTree(fn.sibling, depth);
  return result;
}

function isSuspended(fn: FiberNode): boolean | string {
  if (fn.tag === 23) {
    // offscreen component, child of suspense
    if (!fn.child) {
      return JSON.stringify(fn);
      // let s = '';
      // while (fn.return) {
      //   s += fiberToString(fn.return);
      // }
      // return s;
    }
  }
  if (fn.child && isSuspended(fn.child)) return isSuspended(fn.child);
  if (fn.sibling && isSuspended(fn.sibling)) return isSuspended(fn.sibling);
  return false;
}

const create = async (
  inputElement: React.ReactNode,
  options?: Partial<CreateOptions>
): Promise<Renderer> => {
  const canvas = createCanvas({
    width: options?.width,
    height: options?.height,
    beforeReturn: (canvas) => {
      //@ts-ignore
      canvas.getContext = (type: string) => {
        if (type === 'webgl' || type === 'webgl2') {
          return createWebGLContext(canvas);
        }
      };
    },
  });

  const _fiber = canvas;

  let scene: any = null!;

  let element = inputElement;

  await reconciler.act(async () => {
    scene = render(element, _fiber, {
      frameloop: 'never',
      ...options,
      events: undefined,
    }).getState().scene;
  });

  const _store = mockRoots.get(_fiber)!.store;

  return {
    scene: wrapFiber(scene),
    unmount: async () => {
      await reconciler.act(async () => {
        unmount(_fiber);
      });
    },
    getInstance: () => {
      // this is our root
      const fiber = mockRoots.get(_fiber)?.fiber;
      // console.log('getInstance', _fiber, fiber);
      const root = {
        /**
         * we wrap our child in a Provider component
         * and context.Provider, so do a little
         * artificial dive to get round this and
         * pass context.Provider as if it was the
         * actual react root
         */
        current: fiber.current.child.child,
      };
      // console.log('fiber.current.child.child', fiber.current.child);
      if (fiber.current.child.child) {
        /**
         * so this actually returns the instance
         * the user has passed through as a Fiber
         */
        console.log(outputFiberTree(fiber.current.child as FiberNode, 0));
        // console.log('getPublicRootInstance for', root.current.child);
        return reconciler.getPublicRootInstance(root);
      } else {
        return null;
      }
    },
    update: async (newElement: React.ReactNode, options?: WaitOptions) => {
      element = newElement;
      const fiber = mockRoots.get(_fiber)?.fiber;
      // return new Promise((resolve) => {
      //   function callback() {
      //     console.log('***** UpdateContainer, callback');
      //     resolve();
      //   }
      await reconciler.act(async () => {
        reconciler.updateContainer(newElement, fiber, null);
      });
      // .then(() => {
      //   console.log('reconciler act then');
      // });
      // });
    },
    getFiberTree: () => {
      const fiber = mockRoots.get(_fiber)?.fiber;
      if (fiber.current.child)
        return outputFiberTree(fiber.current.child as FiberNode, 0);
      return 'Unknown fiber';
    },
    isSuspended: () => {
      const fiber = mockRoots.get(_fiber)?.fiber;
      if (fiber.current.child) {
        return isSuspended(fiber.current.child);
      }
      throw new Error('No root to check');
    },
    toTree: () => {
      return toTree(scene);
    },
    toGraph: () => {
      return toGraph(scene);
    },
    fireEvent: createEventFirer(reconciler.act, _store),
    advanceFrames: async (frames: number, delta: number | number[] = 1) => {
      const state = _store.getState();
      const storeSubscribers = state.internal.subscribers;

      const promises: Promise<void>[] = [];

      storeSubscribers.forEach((subscriber) => {
        for (let i = 0; i < frames; i++) {
          if (is.arr(delta)) {
            promises.push(
              new Promise(() =>
                subscriber.ref.current(
                  state,
                  (delta as number[])[i] || (delta as number[])[-1]
                )
              )
            );
          } else {
            promises.push(
              new Promise(() => subscriber.ref.current(state, delta as number))
            );
          }
        }
      });

      Promise.all(promises);
    },
    waitUntilRendered: (options?: Partial<WaitOptions>) => {
      const { waitFor } = asyncUtils(reconciler.act, async () => {
        render(element, canvas);
      });
      return waitFor(() => {
        const fiber = mockRoots.get(_fiber)?.fiber;
        if (fiber.current.child) {
          return !isSuspended(fiber.current.child);
        }
        return true;
      }, options);
    },

    waitFor: (
      callback: () => boolean | void,
      options?: Partial<WaitOptions>
    ) => {
      const { waitFor } = asyncUtils(reconciler.act, async () => {
        // const fiber = mockRoots.get(_fiber)?.fiber
        // if (fiber) {
        //   await reconciler.act(async () => {
        //     reconciler.updateContainer(element, fiber, null, () => null)
        //   })
        // }
        render(element, canvas);
      });
      return waitFor(callback, options);
    },

    getRootFiber: () => {
      const fiber = mockRoots.get(_fiber)?.fiber;
      return fiber.current;
    },
  };
};

const act = (_act as unknown) as Act;

export * as ReactThreeTest from './types';
export { create, act };
