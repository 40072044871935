import type { TreeNode, Tree } from '../types/public';
import { lowerCaseFirstLetter } from './strings';

const treeObjectFactory = (
  type: TreeNode['type'],
  props: TreeNode['props'],
  children: TreeNode['children']
): TreeNode => ({
  type,
  props,
  children,
});

const toTreeBranch = (obj: any[]): TreeNode[] =>
  obj.map((child) => {
    return treeObjectFactory(
      lowerCaseFirstLetter(child.type || child.constructor.name),
      { ...child.__r3f.memoizedProps },
      toTreeBranch([...(child.children || []), ...child.__r3f.objects])
    );
  });

export const toTree = (root: any): Tree =>
  root.children.map((obj: any) =>
    treeObjectFactory(
      lowerCaseFirstLetter(obj.type),
      { ...obj.__r3f.memoizedProps },
      toTreeBranch([
        ...(obj.children as any[]),
        ...(obj.__r3f.objects as any[]),
      ])
    )
  );
