import { resolvePath, NotFound } from './resolvePath';
import { CasState, Path } from '../types';

export default function setDiff(
  state: CasState,
  payload: { path: Path; value: any }
) {
  let { value, path } = payload; // input value

  // console.log('setDiff', path, value);
  const result = resolvePath(state, path);
  if (result.value === NotFound) {
    console.warn('Setting to a path that is not found', path);
    return state;
  }

  const { container, operon, propertyName, childContainer, childName } = result;
  const val = result.value; // found value

  if (childName) {
    // childContainer[childName] = value;
    let childVal = value;
    // clone then set, so immer picks up the difference
    value = childContainer.clone ? childContainer.clone() : childContainer;
    value[childName] = childVal;
  }

  if (propertyName) {
    if (operon) {
      operon.set(container, propertyName, value);
    } else {
      container[propertyName] = value;
    }
  } else {
    if (operon) {
      operon.merge(container, value);
    } else {
      Object.assign(container, value);
    }
  }
  return state;
}
