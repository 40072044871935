import lodash from 'lodash';
import { Euler, Vector3, Matrix4, Quaternion } from 'three';
import {
  EvaluatedNode,
  // EvaluatedNodeType,
  SceneGraphNode,
  EvaluatedImageNode,
  EvaluatedItemNode,
  EvaluatedMaterialNode,
  EvaluatedModelNode,
  EvaluatedNullNode,
  EvaluatedObjectsNode,
  EvaluatedPolyMeshNode,
  EvaluatedStageNode,
  EvaluateOptions,
  MaterialReferencePrimitive,
  OperatorData,
  OpUpdateFunction,
  PlugPrimitive,
  PolyMeshPrimitive,
  SceneGraphPlugType,
  ThreekitApi,
  ThreekitSource,
  TransformPrimitive,
} from '../types';
import { lookupOperator } from '../cas/lookups';

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

function newTransformPrimitive(): TransformPrimitive {
  return {
    type: 'Transform',
    transform: new Matrix4(),
    position: new Vector3(),
    rotation: new Quaternion(),
    euler: new Euler(),
    scale: new Vector3(),
  };
}

export function evaluateNode(
  node: SceneGraphNode,
  opts: EvaluateOptions
  // evalNode: Partial<EvaluatedNode>
  // ): Promise<EvaluatedNode> {
) {
  // await sleep(3000);
  switch (node.type) {
    case 'Camera': {
      return { type: node.type, node };
    }
    case 'Image': {
      const Image = evaluatePlug('Image', node, opts);
      return { type: node.type, node, Image } as EvaluatedImageNode;
    }
    case 'Item': {
      const Proxy = evaluatePlug('Proxy', node, opts);
      return { type: node.type, node, Proxy } as EvaluatedItemNode;
    }
    case 'Light': {
      return { type: node.type, node };
    }
    case 'Material': {
      const Material = evaluatePlug('Material', node, opts);
      return { type: node.type, node, Material } as EvaluatedMaterialNode;
    }
    case 'MaterialLibrary': {
      return { type: node.type, node };
    }
    case 'Model': {
      const Properties = evaluatePlug('Properties', node, opts);
      const Null = evaluatePlug('Null', node, opts);
      const Transform = evaluatePlug(
        'Transform',
        node,
        opts,
        newTransformPrimitive()
      );
      return {
        type: node.type,
        node,
        Null,
        Transform,
        Properties,
      } as EvaluatedModelNode;
    }
    case 'Null': {
      const Properties = evaluatePlug('Properties', node, opts);
      const Null = evaluatePlug('Null', node, opts);
      const Transform = evaluatePlug(
        'Transform',
        node,
        opts,
        newTransformPrimitive()
      );
      return {
        type: node.type,
        node,
        Null,
        Transform,
        Properties,
      } as EvaluatedNullNode;
    }
    case 'Objects': {
      return { type: node.type, node };
    }
    case 'PolyMesh': {
      const PolyMesh = evaluatePlug('PolyMesh', node, opts);
      const Transform = evaluatePlug(
        'Transform',
        node,
        opts,
        newTransformPrimitive()
      );
      const Material = evaluatePlug('Material', node, opts);
      const Properties = evaluatePlug('Properties', node, opts);
      return {
        type: node.type,
        node,
        PolyMesh,
        Properties,
        Transform,
        Material,
      } as EvaluatedPolyMeshNode;
    }
    case 'Scene': {
      return { type: node.type, node };
    }
    case 'Stage': {
      const Proxy = evaluatePlug('Proxy', node, opts);
      return { type: node.type, node, Proxy } as EvaluatedStageNode;
    }
    // default: { // why does this exhaustiveness check not work?
    //   const _exhaustiveCheck: never = node;
    // }
  }
  throw new Error('Invalid node type: ' + node.type);
}
function evaluatePlug(
  plugname: SceneGraphPlugType,
  node: SceneGraphNode,
  evalOptions: EvaluateOptions,
  inputPrimitive?: Partial<PlugPrimitive>
) {
  let primitive = inputPrimitive || { type: plugname };
  return node.plugs[plugname].reduce(
    (primitive: PlugPrimitive, op: OperatorData) => {
      if (op.active === false) return primitive;
      const operator = lookupOperator(plugname, op.type);
      if (!operator) return primitive;
      const opd = operator.def.update
        ? (operator.def.update as OpUpdateFunction)
        : null;
      return opd ? opd(op, primitive, evalOptions) : primitive;
    },
    primitive
  );
}
