import { Vector3, Box3, Math as ThreeMath } from 'three';
import ObjectArrayView from '../../generic/container/ObjectArrayView';

import { bendVertices } from '../operator/bendPolyMesh';
import { computeFaceNormal } from './FaceNormals';

function getFaceUntilWithoutHoles(from, faceBegin, faceIndex) {
  const holeOffset = from.holeOffsets[faceIndex];
  let result = -1;
  if (holeOffset.length === 0) {
    result = from.loopRangeOffsets[faceIndex + 1];
  } else {
    result = faceBegin + holeOffset[0];
  }
  return result;
}

function styleRound(segments) {
  const angle = Math.PI / segments;
  return (segmentIndex) => {
    const curAngle = segmentIndex * angle;
    return {
      offset: Math.sin(curAngle),
      height: 0.5 - Math.cos(curAngle) * 0.5,
    };
  };
}

function styleExtended(segments) {
  const inv = 1.0 / segments;
  return (segmentIndex) => {
    const value = segmentIndex * inv;
    return {
      offset: Math.sqrt(value),
      height: value,
    };
  };
}

/**
 * @param testVertex - vertex to test with
 * @param baseInfo - { x, y, deltaX, deltaY }
 * @param bestDistance - best distance so far
 */
function horizontalDistanceWithDeltas(testVertex, baseInfo, bestDistance) {
  if (baseInfo.deltaY !== 0.0) {
    const alpha = (testVertex.y - baseInfo.y) / baseInfo.deltaY;
    if (alpha >= 0.0 && alpha <= 1.0) {
      const candidateDistance = Math.abs(
        testVertex.x - baseInfo.x - alpha * baseInfo.deltaX
      );
      bestDistance = Math.min(bestDistance, candidateDistance);
    }
  }
  return bestDistance;
}

function computeOuterEdges(from) {
  let swapper;
  let thisVector = new Vector3();
  let thisVectorPrev = new Vector3();

  const nbThisFace = from.loopRangeOffsets.length - 1;
  const thisEdges = [];
  for (let tf = 0; tf < nbThisFace; ++tf) {
    const thisFaceBegin = from.loopRangeOffsets[tf];
    const thisFaceUntil = getFaceUntilWithoutHoles(from, thisFaceBegin, tf);

    from.positions.getAt(thisFaceUntil - 1, thisVectorPrev);
    for (let ti = thisFaceBegin; ti < thisFaceUntil; ++ti) {
      from.positions.getAt(ti, thisVector);

      let thisMinX = thisVector.x,
        thisMaxX = thisVectorPrev.x;
      let thisMinY = thisVector.y,
        thisMaxY = thisVectorPrev.y;
      if (thisMinY > thisMaxY) {
        swapper = thisMinX;
        thisMinX = thisMaxX;
        thisMaxX = swapper;

        swapper = thisMinY;
        thisMinY = thisMaxY;
        thisMaxY = swapper;
      }

      thisEdges.push({
        x: thisMinX,
        y: thisMinY,
        deltaX: thisMaxX - thisMinX,
        deltaY: thisMaxY - thisMinY,
      });

      // swap the 'second' vertices
      swapper = thisVector;
      thisVector = thisVectorPrev;
      thisVectorPrev = swapper;
    }
  }
  return thisEdges;
}

export const ShapeSetBevelStyles = {
  Round: 0,
  Extended: 1,
};

export const SmoothingGroupIds = {
  Front: 1,
  Back: 2,
  Bevel: 4,
};

export function ShapeSet(from) {
  if (from) {
    this.loopRangeOffsets = from.loopRangeOffsets;
    this.loopValueIndices = from.loopValueIndices;
    this.positions = from.positions;
    this.holeOffsets = from.holeOffsets;
    this.bevelSegments = from.bevelSegments;
    this.bevelSmoothingGroups = from.bevelSmoothingGroups || [];
    this.backFaceOffsets = from.backFaceOffsets;
    this.ringOffsets = from.ringOffsets;
  } else {
    this.loopRangeOffsets = new Uint32Array(1);
    this.loopValueIndices = new Uint32Array(0);
    this.positions = new ObjectArrayView(Vector3, 0);
    this.holeOffsets = [];
    this.bevelSegments = [];
    this.backFaceOffsets = 0;
    this.bevelSmoothingGroups = [];
    this.ringOffsets = [];
  }
}

ShapeSet.prototype = {
  constructor: ShapeSet,

  /**
   * Test every pair of edges from both shape and find the smallest distance between the two.
   *
   * @param secondShapeSet - shape set to compute the distance from 'this'
   * @param currentHorizontalOffset - the current offset of the second shape set
   * @param compact - the padding to add to horizontal distance
   * @param lastComputation - (optional) { thisEdges } if 'thisEdges' was already computed in a
   *                          previous call to this function, then let reuse it.
   * @return the offset to use for compact shape sets
   */
  getHorizontalDistance: function (
    secondShapeSet,
    currentHorizontalOffset,
    compact,
    lastComputation
  ) {
    // FOR NOW, stupid brute force algorithm
    let bestDistance = currentHorizontalOffset;

    const nbSecFace = secondShapeSet.loopRangeOffsets.length - 1;

    // compute the edges of 'this' only once
    const thisEdges =
      (lastComputation && lastComputation.thisEdges) || computeOuterEdges(this);
    const secEdges = computeOuterEdges(secondShapeSet);

    for (let tmpSecEdge of secEdges) {
      // a copy of 'tmpSecEdge' with horizontal offset and also describe the bottom vertex
      const secEdge = {
        x: tmpSecEdge.x + currentHorizontalOffset,
        y: tmpSecEdge.y,
        deltaX: tmpSecEdge.deltaX,
        deltaY: tmpSecEdge.deltaY,
      };

      const secMinY = tmpSecEdge.y;
      const secMaxY = tmpSecEdge.y + tmpSecEdge.deltaY;

      // describe the top vertex (and doesn't need the deltas)
      const secEdgeTop = {
        x: secEdge.x,
        y: secMaxY,
      };

      for (let edge of thisEdges) {
        // check if the two edges' vertical intervals intersect
        if (secEdgeTop.y >= edge.y && secEdge.y <= edge.y + edge.deltaY) {
          // the equivalent of 'edgeTop' will be computed in the next iteration
          bestDistance = horizontalDistanceWithDeltas(
            edge,
            secEdge,
            bestDistance
          );

          bestDistance = horizontalDistanceWithDeltas(
            secEdgeTop,
            edge,
            bestDistance
          );
          bestDistance = horizontalDistanceWithDeltas(
            secEdge,
            edge,
            bestDistance
          );
        }
      }
    }

    if (lastComputation) {
      lastComputation.thisEdges = secEdges;
    }
    // Subtract the best distance and the compact padding to the current
    // horizontal offset to get the offset for this second shape set.
    // We multiply by a fudge factor of 1.01 since for some reason certain font letter
    // combinations still retain a small gap. Possibly some precision/rounding issue.
    return currentHorizontalOffset - (bestDistance * 1.01 + compact);
  },

  translateShape: function (direction) {
    const nbVertices = this.positions.length;
    const tmpVector = new Vector3();
    for (let vi = 0; vi < nbVertices; ++vi) {
      this.positions.getAt(vi, tmpVector);
      tmpVector.add(direction);
      this.positions.setAt(vi, tmpVector);
    }
  },

  /**
   * Bevel each faces.
   *
   * It copies a face, then put its bevelled faces after it. Then goes to the
   * next face. So this changes the way the faces are represented from the input.
   *
   * @param xOffset - Offset to move outside the face
   * @param zOffset - Offset the move the up
   * @param segments - number of division to connect the bevel
   * @param {ShapeSetBevelStyles} style - how the bevel should look like
   * @param threshold - ratio over 'xOffset' of how much a bevelled vertex should
   *                    be away from its source vertex
   */
  bevelShapeSet: function (
    xOffset,
    zOffset,
    segments,
    style,
    threshold,
    smoothingAngleDeg
  ) {
    const from = this;
    const maxMoveSquare = xOffset * xOffset * threshold * threshold;
    const smoothingAngleRad = Math.cos(smoothingAngleDeg * ThreeMath.DEG2RAD);

    // need to update the holes, even if the new faces won't have any
    const copyVector = new Vector3();
    let lastVertex = new Vector3();
    let lastEdge = new Vector3();
    let currVertex = new Vector3();
    let currEdge = new Vector3();
    const cornerVector = new Vector3();

    // smoothing group id's are calculated per loop vertex based on angle
    // between adjacent edges, then applied to the corresponding bevel segments
    let currSmoothGroup;
    const bevelSmoothingGroups = from.bevelSmoothingGroups;
    const vertexSmoothingGroups = [];
    const smoothStartBit = Math.log2(SmoothingGroupIds.Bevel);
    const maxShift = 30 - smoothStartBit; // guard against number wrap-around

    // original number of faces
    const nbFaces = this.loopRangeOffsets.length - 1;

    // precompute segment values
    const segmentHeights = [];
    const segmentOffsets = [];
    {
      let styleFunction = null;
      if (style === ShapeSetBevelStyles.Extended) {
        styleFunction = styleExtended(segments);
      } else {
        // Otherwise, use round
        styleFunction = styleRound(segments);
      }

      for (let si = 0; si <= segments; ++si) {
        const { offset, height } = styleFunction(si);
        segmentHeights.push(-height * zOffset);
        segmentOffsets.push(offset);
      }
    }

    // new positions
    const nbVertices = this.positions.length;
    const newPositions = new ObjectArrayView(
      Vector3,
      nbVertices * (segments + 1)
    );
    // these are plane created by the segments. This is an index list based in 'newPositions'
    const bevelSegments = []; // all the bevels will be already triangulated

    // apply the xOffset
    let faceBegin = this.loopRangeOffsets[0];
    let faceUntil = faceBegin;
    let faceOffset = 0;
    let swapper;
    let dotEdges;

    function __writeSegmentVertices(writeAt) {
      // find where the other endpoint of the bevel should be (on the XY plane only)
      // this is done by finding the point where a vertex meet currEdge and lastEdge
      // when offset by their binormal (which is the edge rotated 90 degree CW to bevel outside)
      // the problem is solved by computing it for the edges instead and then the final
      // result is rotated 90 degrees (this is why yMove uses negative x coordinates
      // of the solution), which result in the same.
      dotEdges = currEdge.dot(lastEdge);
      const offset = xOffset / (1 + dotEdges);
      let xMove = -offset * (lastEdge.y + currEdge.y);
      let yMove = offset * (lastEdge.x + currEdge.x);

      const moveLengthSquare = xMove * xMove + yMove * yMove;
      if (moveLengthSquare > maxMoveSquare) {
        // shrink the move to be the maximal valid move
        const scale = Math.sqrt(maxMoveSquare / moveLengthSquare);
        xMove *= scale;
        yMove *= scale;
      }

      // for each parts of the segments, add vertices listed 'nbVertices' appart
      for (let si = 0; si <= segments; ++si) {
        const xySegOffset = segmentOffsets[si];
        cornerVector.x = lastVertex.x + xySegOffset * xMove;
        cornerVector.y = lastVertex.y + xySegOffset * yMove;
        cornerVector.z = segmentHeights[si];

        newPositions.setAt(writeAt, cornerVector);
        writeAt += nbVertices;
      }
    }

    // a range is either a complete face, or the face until the first hole, or between holes
    function __offsetRangeVertices() {
      // read the last vertex of the loop
      from.positions.getAt(from.loopValueIndices[faceUntil - 1], copyVector);

      // read the first vertex
      let lastIndex = from.loopValueIndices[faceBegin];
      from.positions.getAt(lastIndex, lastVertex);

      // compute the last edge
      lastEdge.subVectors(lastVertex, copyVector).normalize();

      const smoothStartIndices = [];
      let smoothWrap = false; // if end of loop should be smoothed with start
      let lookForSmoothStart = true; // flag for collecting range of smooth start indices
      currSmoothGroup = SmoothingGroupIds.Bevel; // reset to default start group id
      let groupShift = 0; // bitshift of smoothing group from starting default

      for (let fi = faceBegin + 1; fi < faceUntil; ++fi) {
        // read the currrent vertex
        const currIndex = from.loopValueIndices[fi];
        from.positions.getAt(currIndex, currVertex);

        // compute the current edge
        currEdge.subVectors(currVertex, lastVertex).normalize();

        __writeSegmentVertices(lastIndex);

        // add smoothing group (increment it if not smoothed with previous edge)
        if (dotEdges < smoothingAngleRad) {
          // if at max bit, reset back to 1 bit above the starting default, to
          // avoid accidental smoothing between end and start of loop
          if (groupShift === maxShift) groupShift = 1;
          currSmoothGroup = SmoothingGroupIds.Bevel << groupShift++;
          lookForSmoothStart = false;
        } else {
          // check for smoothness between end and start, and collect starting
          // smooth indices to match with end
          if (fi === faceBegin + 1) {
            smoothWrap = true;
          }
          if (lookForSmoothStart) smoothStartIndices.push(currIndex - 1);
        }
        vertexSmoothingGroups[currIndex - 1] = currSmoothGroup;

        // swap the edges
        swapper = lastEdge;
        lastEdge = currEdge;
        currEdge = swapper;

        // swap the vertices
        swapper = lastVertex;
        lastVertex = currVertex;
        currVertex = swapper;

        // forward the index
        lastIndex = currIndex;
      }

      //*** do the final vertex, read again the first vertex and compute the edge
      from.positions.getAt(faceBegin, currVertex);
      currEdge.subVectors(currVertex, lastVertex).normalize();
      __writeSegmentVertices(lastIndex);

      // add final smooth group
      if (dotEdges < smoothingAngleRad) {
        if (groupShift === maxShift) groupShift = 1;
        currSmoothGroup = SmoothingGroupIds.Bevel << groupShift++;
      } else {
        if (lookForSmoothStart) smoothStartIndices.push(lastIndex);
      }
      vertexSmoothingGroups[lastIndex] = currSmoothGroup;

      // if end of loop should be smoothed with start, update starting id's to
      // match end
      if (smoothWrap && currSmoothGroup !== SmoothingGroupIds.Bevel) {
        for (let i = 0; i < smoothStartIndices.length; i++) {
          vertexSmoothingGroups[smoothStartIndices[i]] = currSmoothGroup;
        }
      }
    }

    // using the same range as '__offsetRangeVertices', the
    function __writeBevelSegmentIndices() {
      let currIndex = from.loopValueIndices[faceUntil - 1];
      let nextIndex = from.loopValueIndices[faceBegin];

      for (let fi = faceBegin; fi < faceUntil; ) {
        let currLayer = 0;
        let nextLayer = nbVertices;
        for (let si = 0; si < segments; ++si) {
          // first triangle
          bevelSegments.push(currIndex + currLayer);
          bevelSegments.push(nextIndex + currLayer);
          bevelSegments.push(nextIndex + nextLayer);
          bevelSmoothingGroups.push(vertexSmoothingGroups[currIndex]);

          // second triangle
          bevelSegments.push(currIndex + currLayer);
          bevelSegments.push(nextIndex + nextLayer);
          bevelSegments.push(currIndex + nextLayer);
          bevelSmoothingGroups.push(vertexSmoothingGroups[currIndex]);

          // increment the layers
          currLayer = nextLayer;
          nextLayer += nbVertices;
        }

        currIndex = nextIndex;
        ++fi;
        nextIndex = from.loopValueIndices[fi];
      }
    }

    for (let fi = 0; fi < nbFaces; ++fi) {
      const holes = this.holeOffsets[fi];
      const faceStart = faceBegin;
      const faceEnd = this.loopRangeOffsets[fi + 1];
      faceOffset = faceEnd - faceBegin;

      for (let hi = 0; hi < holes.length; ++hi) {
        faceUntil = faceStart + holes[hi];
        __offsetRangeVertices();
        __writeBevelSegmentIndices();
        faceBegin = faceUntil;
      }

      // the final hole (if there was holes), or the whole face itself
      faceUntil = faceEnd;
      __offsetRangeVertices();
      __writeBevelSegmentIndices();
      faceBegin = faceUntil;
    }
    // update the from with the new values
    this.positions = newPositions;
    this.bevelSegments = bevelSegments;
    this.backFaceOffsets = segments * nbVertices;
  },

  /**
   * Bend the whole shape set.
   *
   * Should be applied before the bevel because the rings only remembers their
   * original location.
   *
   * @param angle - how much bending should happen (in degrees)
   */
  bend: function (angle) {
    const v0 = new Vector3();
    const rotation = new Vector3().set(0.0, 0.0, -Math.PI * 0.5);
    const that = this;
    // 1. find the width of the shape set to get the right translation value
    let translation = 0;
    {
      this.positions.getAt(0, v0);
      let minX = v0.x;
      let maxX = v0.x;

      function updateMinMax(posStart, posEnd) {
        for (let pi = posStart; pi < posEnd; ++pi) {
          that.positions.getAt(pi, v0);
          if (v0.x < minX) {
            minX = v0.x;
          } else if (v0.x > maxX) {
            maxX = v0.x;
          }
        }
      }

      if (!this.ringOffsets.length) {
        updateMinMax(1, this.positions.length);
      } else {
        // skip rings in translation calc, so bend pivot is centred based on text only
        // before ring 1
        updateMinMax(1, this.ringOffsets[0].start);
        // between rings
        updateMinMax(this.ringOffsets[0].until + 1, this.ringOffsets[1].start);
        // after ring 2 (usually ring 2 is at the end, but just in case)
        updateMinMax(this.ringOffsets[1].until + 1, this.positions.length);
      }
      translation = (maxX - minX) * -0.5;
    }

    // 2. apply the bend
    v0.set(translation, 0.0, 0.0); // used for translation
    bendVertices(this.positions, 'Z', angle, rotation, v0, true);

    // 3. restore the ring according to their location
    for (let ringItem of this.ringOffsets) {
      // only use up to middle, because that's the outer ring
      let sumX = 0.0;
      let sumY = 0.0;
      for (let vi = ringItem.start; vi < ringItem.middle; ++vi) {
        this.positions.getAt(vi, v0);
        sumX += v0.x;
        sumY += v0.y;
      }
      const divBy = 1.0 / (ringItem.middle - ringItem.start);
      sumX *= divBy;
      sumY *= divBy;

      // reset the z axis
      v0.z = 0.0;
      let vi = ringItem.start;

      // outer ring
      {
        const incrAngle = (2.0 * Math.PI) / (ringItem.middle - ringItem.start);
        for (let angle = 0.0; vi < ringItem.middle; ++vi, angle += incrAngle) {
          v0.x = sumX + Math.cos(angle) * ringItem.outerRadius;
          v0.y = sumY - Math.sin(angle) * ringItem.outerRadius;
          this.positions.setAt(vi, v0);
        }
      }

      // inner ring
      {
        const incrAngle = (2.0 * Math.PI) / (ringItem.until - ringItem.middle);
        for (let angle = 0.0; vi < ringItem.until; ++vi, angle += incrAngle) {
          v0.x = sumX + Math.cos(angle) * ringItem.innerRadius;
          v0.y = sumY + Math.sin(angle) * ringItem.innerRadius;
          this.positions.setAt(vi, v0);
        }
      }
    }
  },
};

function quadBezierAt(v1, v2, v3, t) {
  const oneMinusT = 1 - t;
  const first = oneMinusT * oneMinusT;
  const second = 2.0 * oneMinusT * t;
  const third = t * t;
  return {
    x: first * v1.x + second * v2.x + third * v3.x,
    y: first * v1.y + second * v2.y + third * v3.y,
  };
}

function findHoles(from) {
  const nbLoops = from.loopRangeOffsets.length - 1;
  const holes = new Array(nbLoops);

  const firstVertex = new Vector3();
  const currVector = new Vector3();
  const nextVector = new Vector3();
  const crossVector = new Vector3();
  const loopOrientation = new Vector3();

  let loopBegin = from.loopRangeOffsets[0];
  for (let fi = 0; fi < nbLoops; ++fi) {
    const loopUntil = from.loopRangeOffsets[fi + 1];

    // treat the loop as a face and compute its normal
    computeFaceNormal(
      from.positions,
      from.loopValueIndices,
      loopBegin,
      loopUntil,
      true,
      loopOrientation,
      firstVertex,
      currVector,
      nextVector,
      crossVector
    );

    // if the z component of the normal is greater than zero, it means that
    // loop is a hole (because holes list their points in CCW order)
    holes[fi] = loopOrientation.z > 0.0;

    loopBegin = loopUntil;
  }
  return holes;
}

function putHolesInLoops(from, holes, ownership, boundingBoxes) {
  const boxSize = new Vector3();
  const nbLoops = holes.length;

  for (let inner = 0; inner < nbLoops; ++inner) {
    if (!holes[inner]) continue; // not a hole

    const holeBox = boundingBoxes[inner];

    let ownerIndex = -1,
      ownerArea = -1;
    for (let outer = 0; outer < nbLoops; ++outer) {
      if (holes[outer]) continue; // is a hole

      // find the smallest outer loop containing that inner loop to avoid
      // holes inside holes.
      const outBox = boundingBoxes[outer];
      if (outBox.containsBox(holeBox)) {
        outBox.getSize(boxSize);
        const outBoxArea = boxSize.x * boxSize.y;
        if (ownerIndex === -1 || outBoxArea < ownerArea) {
          ownerIndex = outer;
          ownerArea = outBoxArea;
        }
      }
    }
    ownership[inner] = ownerIndex;
  }
}

function convertLoopsToFaces(from, ownership, boundingBoxes) {
  const nbLoops = ownership.length;
  const readVector = new Vector3();

  const newRangeOffsets = [0];
  const newValueIndices = [];
  const newPositions = new ObjectArrayView(Vector3, from.positions.length);
  const newBoundingBox = [];

  let index = 0;
  let lowestFace = 0; // assume the first face is going to lowest one by default
  let lowestPoint = boundingBoxes[0].min.y;
  for (let li = 0; li < nbLoops; ++li) {
    if (ownership[li] === -1) {
      const holes = []; // offsets for this face
      const faceStartIndex = index;

      // start by copying the outer loop
      const loopBegin = from.loopRangeOffsets[li];
      const loopUntil = from.loopRangeOffsets[li + 1];

      for (let i = loopBegin; i < loopUntil; ++i) {
        from.positions.getAt(i, readVector);
        newPositions.setAt(index, readVector);

        newValueIndices.push(index);
        ++index;
      }

      // add the holes
      for (let lj = 0; lj < nbLoops; ++lj) {
        if (ownership[lj] === li) {
          holes.push(index - faceStartIndex);

          // add the holes indices
          const holeBegin = from.loopRangeOffsets[lj];
          const holeUntil = from.loopRangeOffsets[lj + 1];

          for (let i = holeBegin; i < holeUntil; ++i) {
            from.positions.getAt(i, readVector);
            newPositions.setAt(index, readVector);

            newValueIndices.push(index);
            ++index;
          }
        }
      }

      const bbox = boundingBoxes[li];
      newBoundingBox.push(bbox);

      const testPoint = bbox.min.y;
      if (testPoint < lowestPoint) {
        lowestPoint = testPoint;
        lowestFace = newRangeOffsets.length - 1;
      }

      newRangeOffsets.push(index);
      from.holeOffsets.push(holes);
    }
  }

  from.loopRangeOffsets = new Uint32Array(newRangeOffsets);
  from.loopValueIndices = new Uint32Array(newValueIndices);
  from.positions = newPositions;

  // copy the faces' bounding boxes into 'boundingBoxes' to have an updated version
  for (let b = 0; b < newBoundingBox.length; ++b) {
    boundingBoxes[b] = newBoundingBox[b];
  }
  return lowestFace;
}

/**
 * @param from - shape set info
 * @param anchoredIndex - index of the shape to connect to
 * @param compact - how much compact it should be (0 means barely touching)
 * @param boundingBoxes - updated bounding boxes for the faces
 * @param horizontal - (boolean) true, try to connect horizontally, otherwise, vertically
 */
function compactFaces(from, anchoredIndex, compact, boundingBoxes, horizontal) {
  // anchored face limits
  const anchorBegin = from.loopRangeOffsets[anchoredIndex];
  const anchorUntil = getFaceUntilWithoutHoles(
    from,
    anchorBegin,
    anchoredIndex
  );

  const anchorVertex = new Vector3();
  const testVertex = new Vector3();
  let testBox = null; // assigned by faces

  // use the right weight distance function when compacting horizontally or vertically
  let weightDistance = null;
  if (horizontal) {
    weightDistance = () => {
      // use the distance between the two vertices as the base distance
      const squareDistance = anchorVertex.distanceToSquared(testVertex);

      // if outside the 'slab' of the bounding box, increase the distance
      let extra = 0.0;
      if (anchorVertex.y < testBox.min.y) {
        extra = testBox.min.y - anchorVertex.y;
      } else if (anchorVertex.y > testBox.max.y) {
        extra = anchorVertex.y - testBox.max.y;
      }
      return squareDistance + extra * extra;
    };
  } else {
    weightDistance = () => {
      // use the distance between the two vertices as the base distance
      const squareDistance = anchorVertex.distanceToSquared(testVertex);

      // if outside the 'slab' of the bounding box, increase the distance
      let extra = 0.0;
      if (anchorVertex.x < testBox.min.x) {
        extra = testBox.min.x - anchorVertex.x;
      } else if (anchorVertex.x > testBox.max.x) {
        extra = anchorVertex.x - testBox.max.x;
      }
      return squareDistance + extra * extra;
    };
  }

  const nbFaces = from.loopRangeOffsets.length - 1;
  for (let fi = 0; fi < nbFaces; ++fi) {
    if (fi !== anchoredIndex) {
      // face limits
      const faceBegin = from.loopRangeOffsets[fi];
      let faceUntil = getFaceUntilWithoutHoles(from, faceBegin, fi);

      // set the bounding box for the test
      testBox = boundingBoxes[fi];

      let bestDistance = 1000000000.0;
      const bestPair = { anchorIndex: -1, testIndex: -1 };

      // FOR NOW, brute force algorithm in O(n*m)
      // for each anchor vertex
      for (let ai = anchorBegin; ai < anchorUntil; ++ai) {
        from.positions.getAt(ai, anchorVertex);

        // for each test vertex
        for (let ti = faceBegin; ti < faceUntil; ++ti) {
          from.positions.getAt(ti, testVertex);

          const testDistance = weightDistance();
          if (testDistance < bestDistance) {
            bestPair.anchorIndex = ai;
            bestPair.testIndex = ti;
            bestDistance = testDistance;
          }
        }
      }

      // found the best pair of points, now need to move the points closer
      from.positions.getAt(bestPair.anchorIndex, anchorVertex);
      from.positions.getAt(bestPair.testIndex, testVertex);
      const moveLength = anchorVertex.sub(testVertex).length();
      anchorVertex.multiplyScalar((moveLength + 0) / moveLength);

      // for each vertex in that face, translate by anchorVertex
      faceUntil = from.loopRangeOffsets[fi + 1];
      for (let ti = faceBegin; ti < faceUntil; ++ti) {
        from.positions.getAt(ti, testVertex);
        testVertex.add(anchorVertex);
        from.positions.setAt(ti, testVertex);
      }
    }
  }
}

ShapeSet.fromPath = (path, segments, bottom, compact, ringInfo) => {
  const loopRangeOffsets = [0];
  const loopValueIndices = [];
  const vectorPositions = [];
  const boundingBoxes = [];

  // use half of the segment of lines to help with better compact
  const lineSegments = Math.max(Math.floor(segments * 0.25), 2);

  let looplength = 0;
  let valueIndices = 0;
  let bbox = new Box3();
  let lastVector = null;
  for (let idx = 0; idx < path.commands.length; ++idx) {
    const cmd = path.commands[idx];
    if (cmd.type === 'Q') {
      // quadratics
      looplength += segments;
      const p1 = path.commands[idx - 1];
      const p2 = { x: cmd.x1, y: cmd.y1 };
      const p3 = cmd;
      for (let i = 1; i <= segments; ++i) {
        const pos = quadBezierAt(p1, p2, p3, i / segments);
        const vec = new Vector3(pos.x, bottom - pos.y, 0).multiplyScalar(
          1 / 72.0
        );

        if (!(lastVector && lastVector.x === vec.x && lastVector.y === vec.y)) {
          lastVector = vec;

          loopValueIndices.push(valueIndices);
          bbox.expandByPoint(vec);
          vectorPositions.push(vec);
          ++valueIndices;
        } else {
          --looplength;
        }
      }
    } else if (cmd.type === 'L') {
      // line
      looplength += lineSegments;
      const p1 = path.commands[idx - 1];
      const p2 = { x: (cmd.x + p1.x) * 0.5, y: (cmd.y + p1.y) * 0.5 };
      const p3 = cmd;
      for (let i = 1; i <= lineSegments; ++i) {
        const pos = quadBezierAt(p1, p2, p3, i / lineSegments);
        const vec = new Vector3(pos.x, bottom - pos.y, 0).multiplyScalar(
          1 / 72.0
        );

        if (!(lastVector && lastVector.x === vec.x && lastVector.y === vec.y)) {
          lastVector = vec;

          loopValueIndices.push(valueIndices);
          bbox.expandByPoint(vec);
          vectorPositions.push(vec);
          ++valueIndices;
        } else {
          --looplength;
        }
      }
    } else if (cmd.type !== 'Z') {
      // anything else than an end of loop
      const vec = new Vector3(cmd.x, bottom - cmd.y, 0).multiplyScalar(
        1 / 72.0
      );

      if (!(lastVector && lastVector.x === vec.x && lastVector.y === vec.y)) {
        ++looplength;
        lastVector = vec;

        loopValueIndices.push(valueIndices);
        bbox.expandByPoint(vec);
        vectorPositions.push(vec);
        ++valueIndices;
      }
    } else {
      // end of loop, remove the last point because it's the same as the first one
      --looplength;
      --valueIndices;
      vectorPositions.pop();
      loopValueIndices.pop();

      loopRangeOffsets.push(looplength);

      // add the bounding box and create a new one
      boundingBoxes.push(bbox);
      bbox = new Box3();
    }
  }

  // handle ring
  const ringOffsets = [];
  if (ringInfo) {
    ringInfo.innerRadius /= 72.0;
    ringInfo.outerRadius /= 72.0;
    if (ringInfo.innerRadius >= ringInfo.outerRadius) {
      ringInfo.innerRadius = ringInfo.outerRadius;
    }

    // find total bounding box
    const totalBbox = new Box3().copy(boundingBoxes[0]);
    for (let bi = 1; bi < boundingBoxes.length; ++bi) {
      totalBbox.union(boundingBoxes[bi]);
    }

    // box size
    const totalSize = totalBbox.getSize();
    const dist = Math.min(totalSize.x, totalSize.y);

    const xCenter =
      ringInfo.xPosition < 0 ? totalBbox.min.x - dist : totalBbox.max.x + dist;
    const yCenter =
      0.5 *
      ((totalBbox.min.y - dist) * (1.0 - ringInfo.yPosition) +
        (totalBbox.max.y + dist) * (1.0 + ringInfo.yPosition));

    // segment ratio for outer loop
    const outerSegment = Math.round(
      segments * (ringInfo.outerRadius / ringInfo.innerRadius)
    );

    const ringOffset = {};
    ringOffsets.push(ringOffset);

    // outer loop
    {
      const loopSize = 4 * outerSegment;
      ringOffset.start = looplength;
      ringOffset.middle = looplength + loopSize;
      ringOffset.outerRadius = ringInfo.outerRadius;
      looplength += loopSize;
      const incrAngle = Math.PI / (2.0 * outerSegment);
      let angle = 0.0;
      for (let oi = 0; oi < loopSize; ++oi) {
        vectorPositions.push(
          new Vector3(
            xCenter + Math.cos(angle) * ringInfo.outerRadius,
            yCenter - Math.sin(angle) * ringInfo.outerRadius, // minus to create a CW outer loop
            0.0
          )
        );
        loopValueIndices.push(valueIndices);
        ++valueIndices;
        angle += incrAngle;
      }
      loopRangeOffsets.push(looplength);

      // bouding box
      boundingBoxes.push(
        new Box3(
          new Vector3(
            xCenter - ringInfo.outerRadius,
            yCenter - ringInfo.outerRadius,
            0.0
          ),
          new Vector3(
            xCenter + ringInfo.outerRadius,
            yCenter + ringInfo.outerRadius,
            0.0
          )
        )
      );
    }

    // inner loop
    {
      const loopSize = 4 * segments;
      ringOffset.until = looplength + loopSize;
      ringOffset.innerRadius = ringInfo.innerRadius;
      looplength += loopSize;
      const incrAngle = Math.PI / (2.0 * segments);
      let angle = 0.0;
      for (let oi = 0; oi < loopSize; ++oi) {
        vectorPositions.push(
          new Vector3(
            xCenter + Math.cos(angle) * ringInfo.innerRadius,
            yCenter + Math.sin(angle) * ringInfo.innerRadius,
            0.0
          )
        );
        loopValueIndices.push(valueIndices);
        ++valueIndices;
        angle += incrAngle;
      }
      loopRangeOffsets.push(looplength);

      // bouding box
      boundingBoxes.push(
        new Box3(
          new Vector3(
            xCenter - ringInfo.innerRadius,
            yCenter - ringInfo.innerRadius,
            0.0
          ),
          new Vector3(
            xCenter + ringInfo.innerRadius,
            yCenter + ringInfo.innerRadius,
            0.0
          )
        )
      );
    }
  }

  const from = {
    loopRangeOffsets: new Uint32Array(loopRangeOffsets),
    loopValueIndices: new Uint32Array(loopValueIndices),
    positions: new ObjectArrayView(Vector3, vectorPositions.length),
    // hole offsets contains array and each of them is the offset for the holes
    // listed at end of a face (it's needed for 'earcut')
    holeOffsets: [],
    bevelSegments: [],
    backFaceOffsets: 0, // index where the vertices for the back faces starts
    bevelSmoothingGroups: [],
    ringOffsets,
  };

  // fill the position
  for (let i = 0; i < vectorPositions.length; ++i) {
    from.positions.setAt(i, vectorPositions[i]);
  }

  // if it's a hole, this is the index of the non-hole loop owning it
  const ownership = new Array(loopRangeOffsets.length - 1).fill(-1);

  // find which holes are loops!
  const holes = findHoles(from);

  // find hole ownerships (inside which outer loop that hole is)
  putHolesInLoops(from, holes, ownership, boundingBoxes);

  // split each outer loop into a face (and returns the main face to use as
  // anchor if 'compact' is true)
  const mainFace = convertLoopsToFaces(from, ownership, boundingBoxes);

  // only compact the shape set if it has more than one face
  if (compact !== undefined && from.loopRangeOffsets.length > 2) {
    compactFaces(from, mainFace, compact, boundingBoxes, false);
  }

  return new ShapeSet(from);
};

/**
 * NOTE: can only merge shape set that are not bevelled
 *
 * @param shapeSets - array of shape sets to merge
 * @param offsets - array of vectors to offset each shape set by
 * @return merged shape sets
 */
ShapeSet.mergeShapeSets = (shapeSets, offsets) => {
  // compute the size of each buffers
  let finalLoopRangeOffsetsLength = 1;
  let finalLoopValueIndicesLength = 0; // which is also the length of positions

  const ringOffsets = [];

  for (let shapeSet of shapeSets) {
    finalLoopRangeOffsetsLength += shapeSet.loopRangeOffsets.length - 1;
    finalLoopValueIndicesLength += shapeSet.loopValueIndices.length;
  }

  // init the final 'from' that holds all the buffers
  const finalFrom = {
    loopRangeOffsets: new Uint32Array(finalLoopRangeOffsetsLength),
    loopValueIndices: new Uint32Array(finalLoopValueIndicesLength),
    positions: new ObjectArrayView(Vector3, finalLoopValueIndicesLength),
    holeOffsets: [],
    bevelSegments: [],
    backFaceOffsets: 0,
    bevelSmoothingGroups: [],
    ringOffsets,
  };

  // offsets in which to add the values of each shape set
  let loopRangeOffsetsOffset = 0;
  let loopValueIndicesOffset = 0;

  // indices to write in buffers
  let loopRangeOffsetsIdx = 0;
  let loopValueIndicesIdx = 0;

  const tmpVector = new Vector3();

  // for each shape sets
  for (let si = 0; si < shapeSets.length; ++si) {
    const shapeSet = shapeSets[si];
    const offsetVector = offsets[si];
    const nbValueIndices = shapeSet.loopValueIndices.length;

    // copy the range offsets
    const nbRangeOffsets = shapeSet.loopRangeOffsets.length - 1;
    for (let i = 0; i < nbRangeOffsets; ++i, ++loopRangeOffsetsIdx) {
      finalFrom.loopRangeOffsets[loopRangeOffsetsIdx] =
        shapeSet.loopRangeOffsets[i] + loopRangeOffsetsOffset;
    }

    // update the ring offsets
    for (let ringItem of shapeSet.ringOffsets) {
      ringOffsets.push({
        start: ringItem.start + loopValueIndicesOffset,
        middle: ringItem.middle + loopValueIndicesOffset,
        until: ringItem.until + loopValueIndicesOffset,
        outerRadius: ringItem.outerRadius,
        innerRadius: ringItem.innerRadius,
      });
    }

    // copy the indices and positions
    for (let i = 0; i < nbValueIndices; ++i, ++loopValueIndicesIdx) {
      finalFrom.loopValueIndices[loopValueIndicesIdx] =
        shapeSet.loopValueIndices[i] + loopValueIndicesOffset;

      shapeSet.positions.getAt(i, tmpVector);
      tmpVector.add(offsetVector);
      finalFrom.positions.setAt(loopValueIndicesIdx, tmpVector);
    }

    // add the holes
    finalFrom.holeOffsets.push(...shapeSet.holeOffsets);

    // increment the offsets
    loopValueIndicesOffset += nbValueIndices;
    loopRangeOffsetsOffset += nbValueIndices;
  }
  // conclude the range offsets
  finalFrom.loopRangeOffsets[loopRangeOffsetsIdx] = finalLoopValueIndicesLength;

  return new ShapeSet(finalFrom);
};
