import {
  ActionState,
  Attribute,
  DisplayAttribute,
  ConfigurationType,
  ThreekitSource,
} from '../types';

interface getDisplayAttributesOptions {
  includeHidden?: boolean;
  enabledOnly?: boolean;
}

export default function getDisplayAttributes(
  attributes: Attribute[],
  actionState: ActionState,
  opts?: getDisplayAttributesOptions
): DisplayAttribute[] {
  const { attributeStateMap, configuration } = actionState;

  // const attributesState = this.attributeState;
  const attrs = attributes.reduce((acc: DisplayAttribute[], attr) => {
    let valid = true;
    // if (!opts?.includeHidden) valid = valid && state.visible;
    // if (opts?.enabledOnly) valid = valid && state.enabled;
    if (!valid) return acc;
    // const values = state.values.reduce(
    //   (acc: Array<any>, val: { visible: boolean; enabled: boolean }) => {
    //     let valid = true;
    //     if (!opts?.includeHidden) valid = valid && val.visible;
    //     if (opts?.enabledOnly) valid = valid && val.enabled;
    //     if (valid) acc.push({ ...val });
    //     return acc;
    //   },
    //   []
    // );
    const state = attributeStateMap[attr.id];
    const value = configuration[attr.name];
    // acc.push({ ...attr, ...state, values, value });
    acc.push({ ...attr, ...state, value });
    return acc;
  }, []);
  return attrs;
}
