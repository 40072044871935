import produce from 'immer';
import { CasState, Path, ThreekitSource } from '../types';
import queries from '../queries';
import setToCommit from './setToCommit';

// Initialize CasState. exported for testing purposes only
export function newInitialState(id: string): CasState {
  return {
    id,
    objects: {},
    refs: { HEAD: '', REDO: [] },
    commits: {},
    nodes: {},
    attributes: {},
  };
}

/**
 *
 * @public
 * @param inputs
 * @param options
 */
export default async function fetchContent(
  source: ThreekitSource,
  id: string,
  inputState?: CasState
): Promise<CasState> {
  const state = inputState || newInitialState(id);
  const { data } = await queries.cas.get(source, id);
  const { HEAD, orgId, objects } = data;

  const nextState = produce(state, (draftState) => {
    // console.log('produce', draftState);
    Object.assign(draftState.refs, { HEAD });
    Object.assign(draftState.objects, objects);
    if (orgId) draftState.orgId = orgId; //Object.assign(draftState, orgId);
  });

  // console.log('checkout', commit);
  return setToCommit(id, nextState, { toHEAD: HEAD });
}
